import styled from 'styled-components';
import { FaPen } from "react-icons/fa";

export const Wrapper = styled.div`
    padding-top: 15px;
    font-family: Arial, sans-serif;
`;

export const PaymentContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
`;

export const StudentPaymentCard = styled.div`
   position: relative;
   background-color: #f9f9f9;
   border-radius: 10px;
   padding: 20px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   width: 250px;
   max-height: 150px;
   &::before {
      content: '';
      position: absolute;
      height: 8px;
      left: 0;
      top: 0;
      width: 100%;
      background-color: ${props => !props.isTotalOne ? props.isTotalZero ? '#FF3D00' : '' : '#F6C7B7'} ;
      border-radius: 10px 10px 0px 0px;
      transition: all 0.3s ease;
   };
  
`;

export const StudentName = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 10px;
`;

export const StudentID = styled.p`
    font-size: 1rem;
    color: #555;
`;
export const LessonLeftWrapper = styled.div`
   display: flex;
   align-items: center;
   gap: 5px;
`
export const LessonsLeft = styled.p`
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
`;
export const PackagesList = styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #A09EFF;
    padding: 10px;
    gap: 10px;
    margin-top: 10px;
    z-index: 10;
`
export const PackagesItem = styled.li`
 cursor: pointer;
 background-color: #fff;
 &:hover{
    background-color: #f9f9f9;
 }
`
export const Package = styled.p`

`
export const MoveItem = styled.p`
 cursor: pointer;
 margin-bottom: 10px;
 font-size: 12px;
  &:hover{
    opacity: 0.7;
   
   }
`

export const Icon = styled(FaPen)`
 cursor: pointer;
 &:hover{
    opacity: 0.7;
   
   }
`