import * as styled from "./FeatureBlock.styled"
import newIcon from '../../../assets/icons/new.svg'
function FeatureBlock({list, title}){
  return (
    <styled.Wrapper>
      <styled.Title>{title}</styled.Title>
      <styled.List>
         {list.map(({number, text}) => (
            <styled.Item key={number}>
                  <styled.NumberWrapper>
                     <styled.Number>{number}</styled.Number>
                  </styled.NumberWrapper>
                  <styled.Text>{text}</styled.Text>
            </styled.Item>
         ))}
         <styled.Icon src={newIcon}/>
      </styled.List>
    </styled.Wrapper>
  )
};

export default FeatureBlock;
