export const secondStepFields = [
   {
     title: "Вікова група", 
     formName: "ageGroup",
     selectOptions: [
       {name: "Діти 6-12", value: "kids"},
       {name: "Підлітки 13-17", value: "teenagers"},
       {name: "Дорослі", value: "adults"}
     ]
   },
   {
     title: "Зазначений рівень володіння", 
     formName: "englishLevel",
     selectOptions: [
       {name: "Starter", value: "starter"},
       {name: "Beginner", value: "beginner"},
       {name: "Elementary", value: "elementary"},
       {name: "Pre-Intermediate", value: "pre-intermediate"}
     ]
   },
]