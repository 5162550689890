import styled from "styled-components";

export const Wrapper = styled.div`
   padding-top: 15px;
`

export const Form = styled.form`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;

`

export const ButtonWrapper = styled.div`
   display: flex;
   width: 50%;
   justify-content: center;
   & .back-btn{
      background-color: #F1F1F1 !important;
      box-shadow: 0px 4px 4px 0px #00000040;
      border: none !important;
      margin-right: 100px;
   }
`