import Loader from '../Loader/Loader'
import TopPageTitle from '../TopPageTitle/TopPageTitle'
import * as styled from './MaterialsLevel.styled'

function MaterialsLevel({topTitle, materials, loading, englishLevel, isTeacher}) {
   if(loading) {
      return <Loader />
   }
   
   return(
      <>
         <TopPageTitle value={topTitle} englishLevel={englishLevel}/>
         <styled.MaterialsWrapper>
         {materials.length > 0 ? (
            materials.map((material) =>
            material.topics.map((topic) => (
               <styled.TopicWrapper key={topic.name}>
                  <styled.TopicName>{topic.name}</styled.TopicName>
                  <styled.List>
                  {topic.lessons.map((lesson, index) => (
                     <styled.Item key={lesson._id} isAlternate={index % 2 !== 0} isRevision={lesson.type === "Revision"}>
                        <styled.LessonName href={lesson.url} target="_blank">{lesson.name}</styled.LessonName>
                        {(lesson.answers && isTeacher) && <styled.Answers href={lesson.answers} target="_blank">answers</styled.Answers>}
                     </styled.Item>
                  ))}
                  </styled.List>
               </styled.TopicWrapper>
            ))
            )
         ) : (
            <p>No materials available.</p>
         )}
       </styled.MaterialsWrapper>
      </>
   )
}

export default MaterialsLevel