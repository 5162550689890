import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
`;

export const CurrentPackage = styled.div`
  margin-bottom: 40px;
`;

export const PackageTitle = styled.h2`
 font-family: Raleway;
  font-size: 24px;
  margin-bottom: 10px;
`;

export const PackageInfo = styled.div`
  font-family: Raleway;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Lessons = styled.div`
  font-family: Raleway;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
`;

export const LessonsLeft = styled.div`
  font-family: Raleway;
  color: ${props => props.color};
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const OtherPackages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

export const Package = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: calc(25% - 20px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  background-color: ${props => props.currentPackage ? '#e0ffe0' : '#fff'};
  border-color: ${props => props.currentPackage ? '#28a745' : '#ccc'};
  box-shadow: ${props => props.currentPackage ? '0 4px 6px rgba(40, 167, 69, 0.5)' : '0 4px 6px rgba(0, 0, 0, 0.1)'};
  transform: ${props => props.currentPackage ? 'scale(1.05)' : 'none'};

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 480px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 1280px) {
    width: calc(25% - 20px);
  }
`;

export const PackageHeader = styled.h3`
  font-family: Raleway;
  font-size: 15px;
  margin-bottom: 10px;

  @media (min-width: 1280px){{
    font-size: 20px;
  }
`;

export const OriginalPrice = styled.div`
  font-family: Raleway;
  text-decoration: line-through;
  color: #999;
`;

export const DiscountedPrice = styled.div`
  font-family: Raleway;
  font-size: 24px;
  color: #28a745;
  margin-bottom: 10px;
`;

export const PerLessonPrice = styled.div`
  font-family: Raleway;
  font-size: 18px;
  color: #333;
`;
