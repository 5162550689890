import { useMenu } from "../../context/MenuContext";
import * as styled from "./HamburgerIcon.styled"

function HamburgerIcon({setShow, absoluteStyles}){
   const { showMenu, setShowMenu } = useMenu();
  return (
      <styled.HamburgerIcon onClick={() => setShowMenu(prev => !prev)} absoluteStyles={absoluteStyles}>
         <styled.Line />
         <styled.Line />
         <styled.Line />
      </styled.HamburgerIcon>
  )
};

export default HamburgerIcon;
