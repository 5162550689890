import { Outlet } from "react-router-dom";
import * as styled from "./Admin.styled.jsx";
import LeftPanel from "../../../components/LeftPanel/LeftPanel.jsx";
import { MainContent } from "../../../styles/components/MainContent.styled.jsx";

const navList = [
  { name: 'Головна', link: "general"},
  { name: "Наші учні", link: "ourstudents" },
  { name: "Наші вчителі", link: "ourteachers" },
  { name: "Виплата вчителям", link: "teacher-salary" },
  { name: "Оплата учнів", link: "lesson-payment" },
  { name: "Пробні уроки", link: "trial-lessons" },
  { name: "Зміни уроку", link: "lesson-reschedule" }, 
];
function Admin() {
  return (
    <styled.Wrapper>
      <LeftPanel
        pageTitle={"Admin‘s Room"}
        navList={navList}
        currentRoute={"/admin"}
      />

      <MainContent>
        <Outlet />
      </MainContent>
    </styled.Wrapper>
  );
}

export default Admin;
