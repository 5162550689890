import * as styled from "./ProgressBlock.styled"
import lightningIcon from '../../../assets/icons/lightning.svg'
import { getCurrentLevel, getNextLevel } from "../../../helpers/lessonsLevels/lessonsLevels";

 
function ProgressBlock({data, levels, isTrial}){
  const currentLevel = getCurrentLevel(data.lessonsLevel, levels);
  const nextLevel = getNextLevel(currentLevel, levels);
  const progressToNextLevel = data.lessonsLevel - currentLevel.value;
  const progressPercentage = (progressToNextLevel / (nextLevel.value - currentLevel.value)) * 100;
  
  return (
    <styled.ProgressWrapper>
          <styled.Icon src={lightningIcon} />
          <styled.ProgressTitle>Мій прогрес</styled.ProgressTitle>
          <styled.ProgressText>{!isTrial ? data.lessonsLevel : '0'}/{nextLevel.value}</styled.ProgressText>
          <styled.ProgressContainer>
            <styled.LevelsWrapper>
              <styled.Level>{currentLevel.name}</styled.Level>
              <styled.Level>{nextLevel.name}</styled.Level>
            </styled.LevelsWrapper>
            <styled.ProgressBar>
              <styled.ProgressBarFill width={progressPercentage} />
            </styled.ProgressBar>
          </styled.ProgressContainer>
        </styled.ProgressWrapper>
  )
};

export default ProgressBlock;
