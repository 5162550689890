import styled from 'styled-components';

export const LessonCard = styled.div`
  position: relative;
  background: #F9F9F9;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #D2D1FF80;
  font-family: Raleway;
`;
export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background: ${props => props.isHomeworkOpen ? "#FFE1A2" : 'linear-gradient(90deg, rgba(160, 158, 255, 0.5) 0%, rgba(255, 200, 87, 0.5) 100%)'};
  padding: 10px 20px;
  border-radius: 10px 10px 0px 0px;
`

export const DataWrapper = styled.div`
  
`
export const DataText = styled.p`
   font-family: Raleway;
   font-size: 18px;
   font-weight: 700;
   line-height: 24.2px;
   text-align: left;
   color: #3D3D3D;

`
export const NameWrapper = styled(DataWrapper)`

`

export const NameText = styled(DataText)`

`
export const BottomWrapper = styled.div`
 display: flex;
 flex-direction: column;
 padding: 15px 20px 25px;
`
export const LessonLink = styled.a`
 
`
export const Text = styled.p`
   font-family: Raleway;
   font-size: 17px;
   font-weight: 500;
   line-height: 18.7px;
   text-align: left;

  &:not(:last-child){
   margin-bottom: 15px;
  }

  span{
      font-weight: 700;
      padding-bottom: 3px;
      border-bottom: 1px solid #bbb8b8;
      cursor: pointer;
      transition: 0.3s;
      &:hover{
       color: #A09EFF;
      }
  }
`
export const HomeworkBtn = styled.button`
  display: flex;
  justify-content: flex-end;
  font-family: Raleway;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.7px;
  color: #000000;
  margin-left: auto;
  margin-top: 15px;
  border-radius: 15px;
  background: ${props => !props.homeworkExists ? props.isHomeworkOpen ? "#F1F1F1" : '#FFE1A2' : '#76E281'};
  box-shadow: 0px 2px 2px 0px ${props => props.homeworkExists ? '#76E281' : '#000'};
  padding: 10px 25px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  &:hover{
   opacity: 0.7;
  }
`
export const TeacherHomeworkBtn = styled(HomeworkBtn)`
  margin-right: auto;
  margin-left: 0;
  margin-top: 0;
`