import styled from "styled-components";
import { FaPen } from "react-icons/fa";

export const Wrapper = styled.div`
   padding-top: 15px;
`

export const List = styled.ul`

`

export const Item = styled.li`
   &:not(:last-child){
      margin-bottom: 25px;
   }
`
export const EditWrapper= styled.div`
 display: flex;
 gap: 15px;
`
export const ItemTitle = styled.p `

`
export const Pen = styled(FaPen)`
transition: 0.3s;
cursor: pointer;
 &:hover{
  opacity: 0.7;
 }
`
export const SelectWrapper = styled.div`
 margin-top: 30px;
 max-width: 40%;
`
export const RemoveBtn = styled.button`
   width: 200px !important;
   background-color: #A09EFF;
   padding: 10px;
   transition: 0.3s;
   border-radius: 10px;
   &:hover{
    background-color: red;
   }

`