import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props=> props.open ? '15px 30px' : '25px 30px'};
  border-radius: ${props=> props.open ? '14px 14px 0px 0px' : '14px'};
  background: ${props=> !props.open ? '#FFFFFF' : '#A09EFF'};
  box-shadow:  ${props=> !props.open ? '0px 4px 4px 0px #9B9B9B40' : ''};
=
  cursor: pointer;
  transition: 0.3s;
  &:hover{
   opacity: 0.7;
  }
`
export const TopTitle = styled.p`
  font-family: Raleway;
  font-size: 17px;
  font-weight: 500;
  line-height: 18.7px;
`;

export const DropDownIcon = styled(ReactSVG)`
  transform: rotate(${props => props.open ? '90deg' : '-90deg'});
  width: 20px;
  margin-left: auto;
`
