import styled from "styled-components";
import { ReactSVG } from "react-svg";


export const DropDown = styled.div`
   display: flex;
   align-items: flex-end;
   background: ${props => props.open ? '#E1E0FF' : '#F3F3FC'};
   box-shadow: ${props => props.open ? '' : '0px 4px 4px 0px #9B9B9B40'}; ;
   padding: 20px 10px;
   margin-bottom: ${props => props.withoutMargin ? '' : '30px'};
   cursor: pointer;
   transition: 0.3s;
   width: 100%;
   border-radius: ${props => props.open ? '15px 15px 0px 0px' : '15px'}; ;
   &:hover{
      background: #E1E0FF;
   }
`
export const DropDownTitle = styled.p`
   font-family: Raleway;
   font-size: 15px;
   font-weight: 700;
   line-height: 16.5px;
   margin-left: 10px;
   @media (min-width: 768px){ 
    font-size: 20px;
   }
   @media (min-width: 1280px){ 
   //  margin-right: 160px;
    font-size: 22px;
   }
`
export const BulbIcon = styled(ReactSVG)`
   height: 25px;
`
export const DropDownIcon = styled(ReactSVG)`
   transform: rotate(${props => props.open ? '90deg' : '-90deg'});
   width: 20px;
   
   margin-left: auto;
 
   
`