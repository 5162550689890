import * as styled from "./AvatarBlock.styled"
import avatar from '../../assets/images/avatar.png'

function AvatarBlock({userName}){
  return (
   <styled.AvatarWrapper>
      <img src={avatar} alt="avatar" width={130}/>
      <styled.HelloTitle>Привіт лумчик</styled.HelloTitle>
      <styled.UserName>{userName}</styled.UserName>
   </styled.AvatarWrapper>
  )
};

export default AvatarBlock;
