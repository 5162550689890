import styled from "styled-components";

export const ScheduleGrid = styled.div`
  display: grid;
  grid-template-columns: 80px repeat(7, 1fr);
  grid-template-rows: repeat(24, 50px);
  gap: 1px;
`;

export const GridHour = styled.div`
  display: contents;
`;

export const HourLabel = styled.div`
  background-color: #202020;
  text-align: center;
  padding: 10px;
  border: 1px solid #202020;
  color: #fff;
`;

export const GridCell = styled.div`
  border: 1px solid #ddd;
  min-height: 50px;
  position: relative;

`;

export const LessonCard = styled.div`
  background-color: ${props => !props.iscancel? !props.issubstitute ? !props.isreschedule ? props.istrial ? '#FFC857' : '#A09EFF' : '#8DDDF7' : '#B4B4B4' : "#ff7f7f"};
  padding: 3px 5px;
  margin: 5px;
 
  border-radius: 5px;
  position: absolute;
  width: calc(100% - 10px);
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  &:hover{
    opacity: 0.7;
  }
`;

export const StudentName = styled.p`
  font-family: Raleway;
  font-weight: 600;
  color: #00000;
  font-size: 0.9em;
`

export const LessonTime = styled.p`

  font-weight: 400;
  color: #fff;
  font-size: 0.8em;
`