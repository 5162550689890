import { useEffect, useState } from "react";
import * as styled from "./TeacherInfo.styled.jsx";
import { getTeacherInfo } from "../../../helpers/getTeacherInfo.js";
import { useParams, useNavigate } from "react-router-dom";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle.jsx";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled.jsx";
import axios from "../../../axios";
import RescheduleTeacherBlock from "../../../components/RescheduleTeacherBlock/RescheduleTeacherBlock.jsx";


function TeacherInfo() {
  const [teacher, setTeachers] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [reschedules, setReschedules] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [rescheduleSubmited, setRescheduleSubmited] = useState(false);

  useEffect(() => {
    getTeacherInfo(id).then((data) => setTeachers(data));
  }, [id]);
  useEffect(() => {
    if (!id) return;

    axios
      .get(`lesson-reschedule/${id}`)
      .then((res) => setReschedules(res.data.lesson))
      .catch((err) => console.error(err));
  }, [id]);

  useEffect(() => {
    if(!teacher) return
    axios.get(`lesson-reschedule/${teacher._id}`)
    .then((res) => {
      setReschedules(res.data.lesson);
      if (rescheduleSubmited) setRescheduleSubmited(false); 
    })
    .catch(err => console.error(err))
  },[teacher, rescheduleSubmited])

  if (Object.keys(teacher).length === 0) return;

  const studentClickId = (ageGroup, id) => {
    navigate(`/admin/ourstudents/${ageGroup}/${id}`);
  };
  const handleRemove = (id) => {
    axios
      .delete(`teachers/${id}`)
      .then(() => {
        navigate("/admin/ourteachers");
      })
      .catch((err) => console.error(err));
  };
  
  const handleCloseModal = () => {
    setSelectedLesson(null);
    setRescheduleModal(false)
  };
 
  return (
    <PagesWrapper>
      <TopPageTitle value={teacher.name} />
      <styled.List>
        <styled.Item>
          <styled.ItemTitle>
            Ім’я: {teacher.name} - id:{teacher.teacherId}
          </styled.ItemTitle>
        </styled.Item>
        <styled.Item>
          <styled.ItemTitle>
            Номер телефону: {teacher.phoneNumber}
          </styled.ItemTitle>
        </styled.Item>
        <styled.Item>
          <styled.ItemTitle>Студенти:</styled.ItemTitle>
          <styled.List>
            {teacher?.students?.map(({ name, studentId, _id, ageGroup }) => (
              <styled.StudentItem
                key={studentId}
                onClick={() => studentClickId(ageGroup, _id)}
              >
                <styled.ItemStudentTitle>
                  Ім’я: {name} - id:{studentId}
                </styled.ItemStudentTitle>
              </styled.StudentItem>
            ))}
          </styled.List>
        </styled.Item>
        <styled.BtnWrapper>
          <styled.OpenBtn onClick={() => setIsOpen((prev) => !prev)}>
            Переглянути розклад
          </styled.OpenBtn>

          <styled.RemoveWrapper>
            <styled.RemoveBtn onClick={() => setIsRemove(prev => !prev)}>
            Видалити вчителя
            </styled.RemoveBtn>
            {isRemove &&  <styled.Submit onClick={() => handleRemove(id)}>Натисніть для підтвердження видалення</styled.Submit>}
          </styled.RemoveWrapper>
          
        </styled.BtnWrapper>
        
        {isOpen && (
           <RescheduleTeacherBlock isAdmin teacher={teacher} reschedules={reschedules} handleCloseModal={handleCloseModal} joinedLesson={''} setRescheduleSubmited={setRescheduleSubmited} selectedLesson={selectedLesson} setSelectedLesson={setSelectedLesson} rescheduleModal={rescheduleModal} setRescheduleModal={setRescheduleModal}/>
        )}
        
      </styled.List>
    </PagesWrapper>
  );
}

export default TeacherInfo;
