import * as styled from "./DatePicker.styled"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DateSalaryPicker({startDate, setStartDate, endDate, setEndDate, fetchSalaryData, withoutBtn}){
  return (
    <>
      <styled.DatePickers>
         <div>
            <label>Виберіть початкову дату:</label>
            <DatePicker
               selected={startDate}
               onChange={date => setStartDate(date)}
               dateFormat="yyyy-MM-dd"
            />
         </div>
         <div>
            <label>Виберіть кінцеву дату:</label>
            <DatePicker
               selected={endDate}
               onChange={date => setEndDate(date)}
               dateFormat="yyyy-MM-dd"
            />
         </div>
      </styled.DatePickers>
      {!withoutBtn && <styled.Button onClick={fetchSalaryData}>Отримати дані</styled.Button>}
    </>
  )
};

export default DateSalaryPicker;


