import { useEffect, useState } from 'react';
import * as styled from './Student.styled.jsx'
import { useDispatch } from 'react-redux';
import { fetchAuthMe } from '../../../redux/slices/auth.js';
import LeftPanel from '../../../components/LeftPanel/LeftPanel.jsx';
import { Outlet } from 'react-router-dom';
import { MainContent } from '../../../styles/components/MainContent.styled.jsx';


function Student({isTrial}) {
   const [userData, setUserData] = useState(null)
   const dispatch = useDispatch()
   const navList = [
      {name: 'Головна', link: "general"},
      {name: 'Розклад', link: "schedule"},
      {name: 'Домашнє завдання', link: "homework"},
      {name: 'Оплата', link: "payment"},
      {name: 'Матеріали', link: "materials"},
   ]
   const trialNavList = [
      {name: 'Головна', link: "general"},
      {name: 'Розклад', link: "schedule"},
      {name: 'Домашнє завдання', link: ""},
      {name: 'Ціни', link: "payment"},
      {name: 'Матеріали', link: ""},
   ]

   useEffect(() => {
      const studentData = async() => {
         const data = await dispatch(fetchAuthMe())

         setUserData(data.payload)

      }
      studentData()
      
   }, [dispatch])
   

   if(!userData) return
   return(
      <styled.Wrapper>
         <LeftPanel notAdmin userName={userData.name} pageTitle={"Мій кабінет"} navList={isTrial ? trialNavList : navList} currentRoute={isTrial ? '/trial-student': '/student'}/>
         <MainContent>
            <Outlet/>
         </MainContent>
      </styled.Wrapper>
   )
}

export default Student