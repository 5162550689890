import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import * as styled from "./MyStudents.styled";
import axios from "../../../axios";
import { fetchAuthMe } from "../../../redux/slices/auth";
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled'

function MyStudents() {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [levels, setLevels] = useState({});
  const [editingStudent, setEditingStudent] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState("");

  useEffect(() => {
    if (data) {
      data.students.forEach((student) => {
        fetchLevels(student.ageGroup);
      });
    }
  }, [data]);

  useEffect(() => {
    const authMe = async () => {
      const data = await dispatch(fetchAuthMe());

      return data;
    };
    authMe().then((res) => setData(res.payload));
  }, [dispatch]);

  const fetchLevels = async (ageGroup) => {
    try {
      const response = await axios.get(`/levels/${ageGroup}`);
      setLevels((prev) => ({ ...prev, [ageGroup]: response.data.levels }));
    } catch (error) {
      console.error("Failed to fetch levels", error);
    }
  };

  const handleEditClick = (studentId, currentLevel) => {
    setEditingStudent(studentId);
    setSelectedLevel(currentLevel);
  };

  const handleLevelChange = (e) => {
    setSelectedLevel(e.target.value);
  };

  const handleSaveClick = async (studentId, teacherId) => {
    try {
      await axios.patch(`/students/${studentId}/${teacherId}`, {
        englishLevel: selectedLevel,
      });
      setEditingStudent(null);
      const updatedData = await dispatch(fetchAuthMe());
      setData(updatedData.payload);
    } catch (error) {
      console.error("Failed to update level", error);
    }
  };

  if (!data) return null;

  return (
    <PagesWrapper>
      <TopPageTitle value={"Мої учні"} />
      <styled.StudentsList>
        {data.students.map(
          ({ name, studentId, ageGroup, englishLevel, _id }) => (
            <styled.StudentItem key={studentId}>
              <styled.StudentAvatar>
                <styled.AvatarIcon>👤</styled.AvatarIcon>
              </styled.StudentAvatar>
              <styled.StudentDetails>
                <styled.StudentName>{name} - id({studentId})</styled.StudentName>
                <styled.StudentInfo>Age Group: {ageGroup}</styled.StudentInfo>
                <styled.StudentInfo>
                  English Level:
                  {editingStudent === _id ? (
                    <>
                      <styled.Select
                        value={selectedLevel}
                        onChange={handleLevelChange}
                      >
                        {levels[ageGroup]?.map((level) => (
                          <option key={level} value={level}>
                            {level}
                          </option>
                        ))}
                      </styled.Select>
                      <styled.SaveButton
                        onClick={() => handleSaveClick(_id, data._id)}
                      >
                        Save
                      </styled.SaveButton>
                    </>
                  ) : (
                    <>
                      {editingStudent === _id ? selectedLevel : englishLevel}
                      <styled.EditButton
                        onClick={() => handleEditClick(_id, englishLevel)}
                      >
                        Edit
                      </styled.EditButton>
                    </>
                  )}
                </styled.StudentInfo>
              </styled.StudentDetails>
            </styled.StudentItem>
          )
        )}
      </styled.StudentsList>
    </PagesWrapper>
  );
}

export default MyStudents;
