import React, { useState, useEffect } from 'react';
import * as styled from './HomeworkSubmission.styled';

const HomeworkSubmission = ({onSubmit, lessonId, getHomeworkText}) => {
  const [homeworkText, setHomeworkText] = useState('');

  useEffect(() => {
    const fetchHomeworkText = async () => {
      const text = await getHomeworkText(lessonId);
      if (text) {
        setHomeworkText(text);
      }
    };

    fetchHomeworkText();
  }, [lessonId, getHomeworkText]);

  const handleTextChange = (e) => {
    setHomeworkText(e.target.value);
  };

  return (
    <styled.SubmissionWrapper>
      <styled.TextArea
        value={homeworkText}
        onChange={handleTextChange}
        placeholder="Виконайте ваше домашнє завдання тут"
      />
      <styled.SubmitButton onClick={() => onSubmit(homeworkText)}>Надіслати ДЗ</styled.SubmitButton>
    </styled.SubmissionWrapper>
  );
};

export default HomeworkSubmission;
