import * as styled from './TopPageTitle.styled.jsx'
import arrow from '../../assets/icons/arrow.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerIcon from '../HamburgerIcon/HamburgerIcon';
import { useMenu } from '../../context/MenuContext.js';
import { UseMediaQuery } from '../../hooks/UseMediaQuery.js';


function TopPageTitle({value, englishLevel}){
  const navigate = useNavigate();
  const location = useLocation()
  const { showMenu } = useMenu();
  const {isMobile, isTablet} = UseMediaQuery()
  const handleBackClick = () => {
    navigate(-1);
  };

  const fromLogin = location.state?.fromLogin;

  return (
    <styled.TopWrapper>
      {isTablet && !fromLogin && <styled.Arrow src={arrow} onClick={handleBackClick} />}
      <styled.TitleWrapper>
        <styled.Title>{value}</styled.Title>
        {englishLevel && <styled.EnglishLevel>{englishLevel}</styled.EnglishLevel>}
      </styled.TitleWrapper>
      
      {isMobile && !showMenu && <HamburgerIcon />}
    </styled.TopWrapper>
  );
}

export default TopPageTitle

