import { useState, useEffect } from "react";
import * as styled from "./Modal.styled";
import ModalBlock from "./ModalBlock/ModalBlock";
import StudentsDropDown from "../StudentsDropDown/StudentsDropDown";
import Loader from "../Loader/Loader";

function Modal({
  lesson,
  onClose,
  joinedLesson,
  isReschedule,
  students,
  setSelectedStudent,
  selectedStudent,
  submitReschedule,
  setSelectedCell,
  loading,
  cancelLesson,
  isAdmin,
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <styled.Overlay
      isVisible={isVisible}
      onClick={() => {
        onClose();
        if(isReschedule){
          setSelectedCell(null);
        }
        
      }}
    >
      <styled.ModalContent
        isVisible={isVisible}
        onClick={(e) => e.stopPropagation()}
      >
        <styled.CloseButton
          onClick={() => {
            onClose();
            if(isReschedule){
              setSelectedCell(null);
            }
          }}
        >
          &times;
        </styled.CloseButton>
        {!isReschedule ? (
          loading ? <Loader /> : <ModalBlock lesson={lesson} joinedLesson={joinedLesson} cancelLesson={cancelLesson}/>
        ) : (
          <>
          { loading ? <Loader /> :
          <>
            <styled.Title>Перенести урок?</styled.Title>
            <styled.AfterTitle>Виберіть учня</styled.AfterTitle>
            <StudentsDropDown
              students={students}
              setSelectedStudent={setSelectedStudent}
              selectedStudent={selectedStudent}
            />
            <styled.JoinButton onClick={() => submitReschedule()}>Перенести</styled.JoinButton>
          </> 
            }
          </>
        )}
      </styled.ModalContent>
    </styled.Overlay>
  );
}

export default Modal;
