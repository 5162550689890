import { useState } from "react";
import * as styled from "./SelectDayTime.styled"

const days = [
   {name: "ПН", value:"monday"},
   {name: "ВТ", value:"tuesday"},
   {name: "СР", value:"wednesday"},
   {name: "ЧТ", value:"thursday"},
   {name: "ПТ", value:"friday"},
   {name: "СБ", value:"saturday"},
   {name: "НД", value:"sunday"}
]

function SelectDayTime({ selectedDayTime, setSelectedDayTime }) {
   const handleDayChange = (day) => {
     setSelectedDayTime(prevState => ({
       ...prevState,
       day: day
     }));
   };
 
   const handleTimeChange = (time) => {
     setSelectedDayTime(prevState => ({
       ...prevState,
       time: time
     }));
   };
 
   return (
     <styled.Container>
       {days.map(day => (
         <styled.DayRow key={day.value}>
           <styled.Checkbox
             type="radio"
             name="day"
             onChange={() => handleDayChange(day.value)}
             checked={selectedDayTime.day === day.value}
           />
           <styled.DayLabel>{day.name}</styled.DayLabel>
           {selectedDayTime.day === day.value && (
             <styled.TimeInput
               type="time"
               value={selectedDayTime.time || ""}
               onChange={e => handleTimeChange(e.target.value)}
             />
           )}
         </styled.DayRow>
       ))}
     </styled.Container>
   );
 }

export default SelectDayTime;
