import styled from "styled-components";

export const Wrapper = styled.div`
   padding-top: 15px;
`

export const List = styled.ul`

`

export const Item = styled.li`
   &:not(:last-child){
      margin-bottom: 25px;
   }
`

export const ItemTitle = styled.p `

`

export const StudentItem = styled.li`
   cursor: pointer;
   transition: 0.3s;
   &:not(:last-child){
      margin-bottom: 25px;
   };

   &:hover{
      opacity: 0.7;
   }
`

export const ItemStudentTitle = styled.p `

`
export const BtnWrapper = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 50px;
`
export const RemoveWrapper = styled.div`
   display: flex;
   flex-direction: column;
`
export const RemoveBtn = styled.button`
   width: 200px !important;
   background-color: #A09EFF;
   padding: 10px;
   transition: 0.3s;
   border-radius: 10px;
   &:hover{
    background-color: red;
   }

`
export const Submit = styled.span`
   padding: 5px;
   background-color: red;
   transition: 0.3s;
   cursor: pointer;
   margin-top: 10px;
   &:hover{
    opacity: 0.7;
   }
`
export const OpenBtn = styled(RemoveBtn)`
   background-color: orange;
   &:hover{
      background-color: orange;
    opacity: 0.7;
   }
`