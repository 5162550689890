import { useSelector } from "react-redux";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import * as styled from "./Payment.styled";
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaTimesCircle,
} from "react-icons/fa";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";

const lessonPackages = [
  {
  
    perLesson: "300",
    price: [
      {
        lessons: "8",

        discountedPrice: 2400,
        perLesson: 300,
      },
      {
        lessons: "16",
        originalPrice: 4800,
        discountedPrice: 4560,
        perLesson: 285,
      },
      {
        lessons: "32",
        originalPrice: 9600,
        discountedPrice: 8480,
        perLesson: 265,
      },
      {
        lessons: "50",
        originalPrice: 15000,
        discountedPrice: 12250,
        perLesson: 245,
      },
    ],
  }
];


function Payment({isTrial}) {
  const { data } = useSelector((state) => state.auth);
  const getLessonsLeftColor = (lessonsLeft) => {
    if (lessonsLeft <= 0) return "#FF0000";
    if (lessonsLeft === 1) return "#FFA500";
    return "#333";
  };
  const getLessonsLeftIcon = (lessonsLeft) => {
    if (lessonsLeft <= 0) return <FaTimesCircle />;
    if (lessonsLeft === 1) return <FaExclamationTriangle />;
    return <FaCheckCircle />;
  };
  return (
    <PagesWrapper>
      <TopPageTitle value={!isTrial ? "Оплата" : "Ціни"} />
      {!isTrial ? (
        <>
        <styled.CurrentPackage>
        <styled.PackageInfo>
          <styled.Lessons>
            Загальна кількість уроків: {data.lessonPackage}
          </styled.Lessons>
          <styled.LessonsLeft color={getLessonsLeftColor(data.totalLessons)}>
            {getLessonsLeftIcon(data.totalLessons)} Залишилось уроків:
            {data.totalLessons}
          </styled.LessonsLeft>
        </styled.PackageInfo>
      </styled.CurrentPackage>
      <styled.PackageTitle>Ваш пакет уроків:</styled.PackageTitle>
      <styled.OtherPackages>
        {lessonPackages.map(({ perLesson, price }) => {
          if (perLesson !== data.lessonPrice) return null;
          return price.map((pkg) => (
            <styled.Package
              key={pkg.lessons}
              currentPackage={data.lessonPackage === pkg.lessons}
            >
              <styled.PackageHeader>{pkg.lessons} уроків</styled.PackageHeader>
             {pkg.originalPrice ? <styled.OriginalPrice>{pkg.originalPrice} грн</styled.OriginalPrice> : ''}
              <styled.DiscountedPrice>{pkg.discountedPrice} грн</styled.DiscountedPrice>
              <styled.PerLessonPrice>{pkg.perLesson} грн за урок</styled.PerLessonPrice>
            </styled.Package>
          ));
        })}
      </styled.OtherPackages>
        
        </>
      ) : (
        <>
          <styled.PackageTitle>Можливі пакети уроків:</styled.PackageTitle>
          <styled.OtherPackages>
            {lessonPackages.map(({ perLesson, price }) => {
   
              return price.map((pkg) => (
                <styled.Package
                  key={pkg.lessons}
                  currentPackage={data.lessonPackage === pkg.lessons}
                >
                  <styled.PackageHeader>{pkg.lessons} уроків</styled.PackageHeader>
                {pkg.originalPrice ? <styled.OriginalPrice>{pkg.originalPrice} грн</styled.OriginalPrice> : ''}
                  <styled.DiscountedPrice>{pkg.discountedPrice} грн</styled.DiscountedPrice>
                  <styled.PerLessonPrice>{pkg.perLesson} грн за урок</styled.PerLessonPrice>
                </styled.Package>
              ));
            })}
          </styled.OtherPackages>
        </>
      )}
      
    </PagesWrapper>
  );
}

export default Payment;
