import styled from "styled-components";
import { ReactSVG } from "react-svg";

export const ProgressWrapper = styled.div`
  font-family: Raleway;
  position: relative;
  background: #f3f3fc;
  box-shadow: 0px 4px 10px 0px #9c9c9c40;
  padding: 35px 10px;
`;

export const Icon = styled(ReactSVG)`
  position: absolute;
  left: -5%;
  top: -15%;
  @media (min-width: 600px) and (max-width: 1280px){
   left: 0%;
  }
`;

export const ProgressTitle = styled.p`
  font-family: Raleway;
  font-size: 15px;
  font-weight: 700;
  line-height: 16.5px;
  text-align: center;
  margin-bottom: 25px;
  @media (min-width: 1280px){ 
      font-size: 22px;
  }
`;

export const ProgressText = styled.p`
  font-family: Raleway;
  font-size: 50px;
  font-weight: 800;
  line-height: 55px;
  color: #a09eff;
  text-align: center;
  margin-bottom: 30px;
  @media (min-width: 1280px){ 
      font-size: 70px;
      margin-bottom: 60px;
  }
`;

export const ProgressContainer = styled.div``;
export const LevelsWrapper = styled.div`
 display: flex;
 align-items: center;
 justify-content: space-between;
 margin-bottom: 20px;
`
export const Level = styled.p`
   font-size: 13px;
   font-weight: 700;
   line-height: 14.3px;

`
export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 5px;
  background-color: #a09eff; 
  border-radius: 5px;
`;

export const ProgressBarFill = styled.div`
  position: absolute;
  height: 100%;
  background-color: #ffcc00;
  width: ${(props) => props.width}%;
  border-radius: 5px;
  transition: width 0.3s;
`;