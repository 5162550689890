import { Outlet } from 'react-router-dom'
import LeftPanel from '../../../components/LeftPanel/LeftPanel.jsx'
import { MainContent } from '../../../styles/components/MainContent.styled.jsx'
import * as styled from './Teacher.styled.jsx'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchAuthMe } from '../../../redux/slices/auth.js'

const navList = [
   {name: 'Головна', link: "general"},
   {name: 'Матеріали', link: "materials"},
   {name: 'Графік', link: "schedule"},
   {name: 'Відмітка про урок', link: "lesson-report"},
   {name: 'Мої учні', link: "my-students"},
   {name: 'Пройдені уроки', link: "marked-lessons"},
   {name: 'Фінанси', link: "finance"},  
]

function Teacher() {
   const [userData, setUserData] = useState(null)
   const dispatch = useDispatch()
   useEffect(() => {
      const studentData = async() => {
         const data = await dispatch(fetchAuthMe())

         setUserData(data.payload)

      }
      studentData()
      
   }, [dispatch])
   if(!userData) return
   return(
      <styled.Wrapper>
         <LeftPanel notAdmin userName={userData.name} pageTitle={"Кабінет вчителя"} navList={navList} currentRoute={'/teacher'}/>
         <MainContent>
            <Outlet />
         </MainContent>
      </styled.Wrapper>
   )
}

export default Teacher