import * as styled from './CheckboxSelect.styled'


function CheckboxSelect({selectOptions, setFormValue, formName}){ 
   
   return (
      <styled.Wrapper>
          
            <styled.List>
               {selectOptions.map(({value, name}) => (
                  <styled.Item key={value} onClick={() => setFormValue(formName, value)}>
                     <styled.RadioButton value={value} id={value} name={formName}/>
                     <styled.Label htmlFor={value}>{name}</styled.Label>
                  </styled.Item>
               ))}
              
            </styled.List>
      </styled.Wrapper>
   )
}

export default CheckboxSelect