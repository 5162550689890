import { useState } from "react";
import CheckboxSelect from "../../CheckboxSelect/CheckboxSelect.jsx";
import * as styled from "./SecondStep.styled.jsx";
const days = [
   {name: "ПН", value:"monday"},
   {name: "ВТ", value:"tuesday"},
   {name: "СР", value:"wednesday"},
   {name: "ЧТ", value:"thursday"},
   {name: "ПТ", value:"friday"},
   {name: "СБ", value:"saturday"},
   {name: "НД", value:"sunday"}
]
function SecondStep({ secondStepFields, register, setFormValue, isTrial }) {
   const [schedule, setSchedule] = useState([]);
   const [selectedDay, setSelectedDay] = useState(null);
   const [inputState, setInputState] = useState({}); // Track input states

   const handleChange = (day) => {
      if (selectedDay === day) {
          // Deselect the day
          setSelectedDay(null);
          setSchedule(prevSchedule => prevSchedule.filter(entry => entry.day !== day));
          setInputState(prevState => ({ ...prevState, [day]: "" }));
      } else {
          // Select a new day or reselect the same day
          setSelectedDay(day);
          const updatedSchedule = schedule.map(entry => {
              if (entry.day === day) {
                  return { ...entry, time: '' }; // Update time for existing day
              }
              return entry;
          });
          const dayExists = updatedSchedule.some(entry => entry.day === day);
          if (!dayExists) {
              updatedSchedule.push({ day, time: '' }); // Add new day if it doesn't exist
          }
          setSchedule(updatedSchedule);
      }
  };
  

   const handleTimeChange = (day, time) => {
      const updatedSchedule = schedule.map(entry =>
         entry.day === day ? { ...entry, time } : entry
      );
      setSchedule(updatedSchedule);
      setInputState(prevState => ({ ...prevState, [day]: time }));
   };

   const scheduleComplete = (day) => {
      setFormValue("classSchedule", schedule);
      setInputState(prevState => ({
         ...prevState,
         [day]: "completed"
      }));
   };

   const resetDay = (day) => {
      setSelectedDay(null);
      setSchedule(prevSchedule => prevSchedule.filter(entry => entry.day !== day));
      setInputState(prevState => ({ ...prevState, [day]: "" }));
   };

   return (
      <styled.Wrapper>
         {secondStepFields.map(({ title, selectOptions, formName }) => (
            <styled.CheckBoxWrapper key={title}>
               <styled.Title>{title}</styled.Title>
               <CheckboxSelect title={title} register={register} selectOptions={selectOptions} formName={formName} setFormValue={setFormValue} />
            </styled.CheckBoxWrapper>
         ))}
         <styled.CheckBoxWrapper>
            <styled.Title>{isTrial ? "День та час пробного" : 'Розклад'}</styled.Title>
            <styled.List>
               {days.map(({ value, name }) => {
                  const isChecked = schedule.some(entry => entry.day === value);
                  const isInputActive = inputState[value] !== undefined && inputState[value] !== '';
                  const isCompleted = inputState[value] === "completed";
                  return (
                     <styled.Item key={value}>
                        <styled.RadioButton
                           isChecked={selectedDay === value || isChecked}
                           value={value}
                           id={value}
                           onChange={() => handleChange(value)}
                           onClick={() => {
                              if (selectedDay === value) {
                                 handleChange(value);
                              } else {
                                 setSelectedDay(value);
                                 if (!isChecked) {
                                    setSchedule(prevSchedule => [...prevSchedule, { day: value, time: '' }]);
                                }
                              }
                           }}
                        />
                        <styled.Label htmlFor={value}>{name}</styled.Label>

                        {(isChecked || selectedDay === value) && (
                           <styled.InputWrapper>
                              <styled.InputText>Час</styled.InputText>
                              <styled.Input
                                 type="time"
                                 value={inputState[value] || ''}
                                 onChange={(e) => handleTimeChange(value, e.target.value)}
                              />
                           </styled.InputWrapper>
                        )}

                        <styled.Plus onClick={() => {
                           if (isCompleted) {
                              // Reset day if already completed
                              resetDay(value);
                           } else if (isInputActive) {
                              // Complete the input if active
                              scheduleComplete(value);
                           } else {
                              // Select the day for input
                              handleChange(value);
                           }
                        }}>
                           {isCompleted ? '✓' : isInputActive ? '+' : ''}
                        </styled.Plus>
                     </styled.Item>
                  );
               })}
            </styled.List>
         </styled.CheckBoxWrapper>
      </styled.Wrapper>
   );
}


export default SecondStep;
