
import { useLocation } from 'react-router-dom'
import * as styled from './NavBar.styled.jsx'

function NavBar({navList, currentRoute, setShowMenu}) {
   const location = useLocation()
   return (
      <styled.List>
         {navList.map(({name, link}) => (
            <styled.Item isClosed={link.length === 0} key={name} currentroute={location.pathname === `${currentRoute}/${link}`} onClick={() => setShowMenu(false)}>
               <styled.StyledLink to={link}>{name}</styled.StyledLink>
               {link.length === 0 && <styled.ClosedIcon>🚫</styled.ClosedIcon>}
            </styled.Item>
         ))}
      </styled.List>
   ) 
}

export default NavBar