import styled from "styled-components";


export const Wrapper = styled.div`
   display: flex;
   align-items: center;
   padding: 13px 0px 0px 0px;
   margin-bottom: ${props => props.notAdmin ? "10px" : "100px"} ;

   @media (max-width: 768px) {
     
      flex-direction: column;
      margin-bottom: 150px;
   };
   @media (min-width: 768px) {
      padding: 20px 20px 0px;
   }
`

export const PageTitle = styled.p`
   font-family: Raleway;
   font-size: 22px;
   font-weight: 900;
   line-height: 24.2px;
   margin-left: 15px;

`
export const TopWrapper = styled.div` 
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding-right: 15px;

`
