import styled from "styled-components";
import { FaTrash } from "react-icons/fa";

export const RemoveIcon = styled(FaTrash)`

   position: absolute;
   top: 10px;
   right: 10px;
   transition: 0.3s;
   cursor: pointer;
   &:hover{
    opacity: 0.7;
    fill: red;
   }
`