import styled from "styled-components";


export const Wrapper = styled.div`
  &:not(:last-child){
   margin-bottom: 20px;
  }
`
export const InputName = styled.p`
   font-family: Raleway;
   font-size: 13px;
   font-weight: 800;
   line-height: 14.3px;
   text-align: left;
   margin-bottom: 5px;
`
export const SelectWrapper = styled.div`
   border: 1px solid #8E8E8E;
   border-radius: ${props => props.teacherClick ? '10px 10px 0px 0px' : "10px"};
   padding: 10px;
   box-shadow: ${props => props.teacherClick ? '0px 0px 4px 0px' : "0px 4px 4px 0px"} #00000040;
   font-family: Raleway;
   font-size: 16px;
   font-weight: 400;
   cursor: pointer;
   &:hover {
      border: 1px solid #A09EFF;
   }
`

export const TeacherWrapper = styled.div`
   border: 1px solid #8E8E8E;
   border-radius: 0px 0px 10px 10px;
   padding: 10px;
   box-shadow: 0px 4px 4px 0px #00000040;
   
`

export const TeacherInput = styled.input`
   border: 1px solid #8E8E8E;
   border-radius: 10px;
   padding: 10px;
   box-shadow: 0px 2px 2px 0px #00000040;
   font-size: 12px;
   width: 100%;
`

export const TeacherList = styled.ul`
   
`

export const TeacherItem = styled.option`
   padding: 10px 15px;
   cursor: pointer;
   transition: 0.3;
   &:not(:last-child){
      border-bottom: 1px solid #8E8E8E;
   };
   &:hover{
      opacity: 0.7;
   }
   
`

export const TeacherName = styled.p`
   font-family: Raleway;
   font-size: 10px;
   font-weight: 600;
   line-height: 11px;

`

export const Loader = styled.span`

`