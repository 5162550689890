import styled from "styled-components";

export const Wrapper = styled.div`
   display: flex;
   height: 100vh;
  
`

export const Title = styled.h1`

`