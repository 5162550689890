import React, { useState, useEffect } from "react";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import * as styled from "./LessonPayment.styled";
import { getAllStudents } from "../../../helpers/getAllStudents";
import { useNavigate } from "react-router-dom";
import axios from '../../../axios'
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";

const packages = [
  { name: "8", value: "8" },
  { name: "16", value: "16" },
  { name: "32", value: "32" },
  { name: "50", value: "50" },
];
function LessonPayment() {
  const [students, setStudents] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isSuccessUpdated, setIsSuccessUpdated] = useState(null)
  const navigate = useNavigate();
  useEffect(() => {
    getAllStudents().then((students) => {
      const simplifiedStudents = students.map((student) => ({
        _id: student._id,
        name: student.name,
        totalLessons: student.totalLessons,
        studentId: student.studentId,
        ageGroup: student.ageGroup,
      }));

      setStudents(simplifiedStudents);
    });
  }, []);

  const changePackage = async (value, id) => {
    await axios.post(`students/update-total/${id}/${value}`)
    .then(() => {
      setIsSuccessUpdated(true)
      setIsShow(false)
   })
    .catch(() => {
      setIsSuccessUpdated(false)
      setIsShow(false)
   })
  };
  return (
    <PagesWrapper>
      <TopPageTitle value={"Оплата учнів"} />
      <styled.PaymentContainer>
        {students
          .sort((a, b) => a.totalLessons - b.totalLessons)
          .map(({ studentId, _id, name, totalLessons, ageGroup }) => (
            <styled.StudentPaymentCard
              key={_id}
              isTotalZero={totalLessons === 0 || totalLessons < 0}
              isTotalOne={totalLessons === 1}
            >
               <styled.MoveItem onClick={() => navigate(`/admin/ourstudents/${ageGroup}/${_id}`)}>Переглянути інфу</styled.MoveItem>
              <styled.StudentName>{name}</styled.StudentName>
              <styled.StudentID>ID: {studentId}</styled.StudentID>
              <styled.LessonLeftWrapper>
                <styled.LessonsLeft>
                 залишилось занять - {totalLessons} 
                </styled.LessonsLeft>
                <styled.Icon onClick={() => {
                  setSelectedId(_id)
                  setIsShow((prev) => !prev)
                  }} />
               
              </styled.LessonLeftWrapper>
              {(isSuccessUpdated && selectedId === _id) && <p style={{color: 'green'}}>Кількість занять успішно оновлено</p>}
               {(isSuccessUpdated === false && selectedId === _id) && <p style={{color: 'red'}}>Щось пішло не так</p>}
              {(isShow && selectedId === _id) && (
                <styled.PackagesList>
                  {packages.map(({ name }) => (
                    <styled.PackagesItem
                      onClick={() => changePackage(name, _id)}
                    >
                      <styled.Package>{name}</styled.Package>
                    </styled.PackagesItem>
                  ))}
                </styled.PackagesList>
              )}
            </styled.StudentPaymentCard>
          ))}
      </styled.PaymentContainer>
    </PagesWrapper>
  );
}

export default LessonPayment;
