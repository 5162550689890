import { useEffect, useState } from "react";
import * as styled from "./HomeworkCard.styled";

function HomeworkCard({
  teacherName,
  lessonDate,
  comment,
  url,
  lessonName,
  lessonType,
  commentForTeacher,
  setHomeworkOpen,
  lessonId,
  setLessonId,
  selectedLesson,
  getHomeworkText,
  homeworkOpen
}) {
  
  const [homeworkExists, setHomeworkExists] = useState(false);

  useEffect(() => {
    if (!teacherName) return;
    const fetchHomeworkText = async () => {
      try {
        const text = await getHomeworkText(lessonId);
        if (text) {
          setHomeworkExists(true);
        }
      } catch (error) {
        console.error(error);
      } 
    };

    fetchHomeworkText();
  }, [lessonId, getHomeworkText, teacherName]);

  if (lessonType === "failed-by-student") return;
  return (
    <styled.LessonCard>
      <styled.TopWrapper isHomeworkOpen={lessonId === selectedLesson}>
        {teacherName ? (
          <styled.NameWrapper>
            <styled.NameText>{teacherName}</styled.NameText>
          </styled.NameWrapper>
        ) : (
          <styled.TeacherHomeworkBtn
            onClick={() => {
              setHomeworkOpen((prev) => !prev);
              setLessonId(lessonId);
            }}
            isHomeworkOpen={lessonId === selectedLesson}
          >
            Перевірити ДЗ
          </styled.TeacherHomeworkBtn>
        )}
        <styled.DataWrapper>
          <styled.DataText>
            {new Date(lessonDate).toLocaleDateString()}
          </styled.DataText>
        </styled.DataWrapper>
      </styled.TopWrapper>
      <styled.BottomWrapper>
        <styled.Text>Коментар до уроку: {comment}</styled.Text>
        {commentForTeacher && (
          <styled.Text>
            Помітка про урок для себе: {commentForTeacher}
          </styled.Text>
        )}
        <styled.LessonLink href={url} target="_blank">
          <styled.Text>
            Домашнє завдання: <span>{lessonName}</span>
          </styled.Text>
        </styled.LessonLink>

        {teacherName && (
          <styled.HomeworkBtn
            onClick={() => {
              setHomeworkOpen((prev) => !prev);
              setLessonId(lessonId);
            }}
            isHomeworkOpen={lessonId === selectedLesson}
            homeworkExists={homeworkExists}
          >
            {!homeworkOpen ? homeworkExists ? 'Переглянути ДЗ' : 'Виконати ДЗ' : 'Закрити'}
          </styled.HomeworkBtn>
        )}
      </styled.BottomWrapper>
    </styled.LessonCard>
  );
}

export default HomeworkCard;
