import { getByGroupAndLevel } from "./getByGroupAndLevel";

export const fetchMaterials = async (setLoading, group, level, setMaterials) => {
   setLoading(true);
   try {
     const materials = await getByGroupAndLevel(group, level);
     setMaterials(materials);
   } catch (error) {
     console.error('Error fetching materials:', error);
   } finally {
     setLoading(false);
   }
 };