import styled from 'styled-components';

export const TopWrapper = styled.div`
 margin-bottom: 40px;

`
export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #A09EFF;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
`;

export const Select = styled.div`
  padding: 3px;
  border-radius: 2px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#A09EFF' : ''};
  color: ${props => props.selected ? '#fff' : ''};
  transition: 0.4s;
  &:hover{
    background-color: #A09EFF;
    color: #fff;
  }
  &:not(:last-child){
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    
  }
`;

export const Option = styled.p`
  font-family: Raleway;
  font-weight: 400;
`

export const Input = styled.input`
  font-family: Raleway;
  font-weight: 400;
  padding: 10px;
  border: 1px solid #A09EFF;
  border-radius: 4px;
  margin-bottom: 10px;

  &::placeholder{
    font-size: 12px;
  }
`;
