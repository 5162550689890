import { useParams } from 'react-router-dom';
import MaterialsLevel from '../../../components/MaterialsLevel/MaterialsLevel'
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled'
import { useEffect, useState } from 'react';
import { fetchMaterials } from '../../../helpers/materials/fetchMaterials';
function MaterialsGroupLevel(){
   const [loading, setLoading] = useState(false);
   const [materials, setMaterials] = useState([]);
   const {group, level} = useParams()

   useEffect(() => {
      fetchMaterials(setLoading, group, level, setMaterials)
   },[group, level])

   return(
      <PagesWrapper>
         <MaterialsLevel loading={loading} topTitle={'Матеріали'} materials={materials} isTeacher/>  
      </PagesWrapper>
      
   )
}

export default MaterialsGroupLevel