import { useMediaQuery } from 'react-responsive'

export const UseMediaQuery = () => {
   const isTablet = useMediaQuery({
      query: '(min-width: 769px)'
   })
   const isDesktop = useMediaQuery({
      query: '(min-width: 1280px)'
   })
   const isMobile = useMediaQuery({
      query: '(max-width: 768px)'
   })

   return {isTablet, isDesktop, isMobile}
}