import axios from '../../axios'

function formatDate(date) {
   return date.toISOString().split('T')[0];
}

export const fetchSalaryData = async (id, startDate, endDate, setTotalSalary, setLessons) => {
   try {
     const response = await axios.get(`/teachers/${id}/salary`, {
        params: {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate)
        }
      });

      setTotalSalary(response.data.totalSalary);
      if(setLessons){
         setLessons(response.data.lessons);
      }
      
    
   } catch (error) {
     console.error('Error fetching salary data:', error);
   }
 };