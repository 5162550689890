import styled from "styled-components";

export const Wrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 35px;
`

export const List = styled.ul`
   display: flex; 
  
`
export const Item = styled.li`
   display: flex; 
   align-items: center;
`

export const ItemWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 15px 23px;
   border-radius: 50%;
   background-color: ${props => props.iscurrentstep ? '#A09EFF' : '#DBDAFF'} ;
   width: 55px;
   height: 55px;
`

export const StepTitle = styled.div`
   font-family: Raleway;
   font-size: 22px;
   font-weight: 700;
   line-height: 24.2px;
   color: #FFFFFF;
`

export const Border = styled.div`
   border: 1px solid #A09EFF;
   width: 78px;
`