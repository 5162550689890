import { useState } from "react";
import { daysOfWeek, processLessons } from "../../helpers/teacherSchedule/teacherSchedule";
import * as styled from "./RescheduleTeacherBlock.styled"
import ScheduleGrid from "../ScheduleGrid/ScheduleGrid";
import axios from "../../axios";
import Modal from "../Modal/Modal";
const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
function RescheduleTeacherBlock({teacher, reschedules, handleCloseModal, joinedLesson, isAdmin, setRescheduleSubmited, selectedLesson, setSelectedLesson, rescheduleModal, setRescheduleModal}){
   const [selectedDayTime, setSelectedDayTime] = useState({});
   const [selectedCell, setSelectedCell] = useState(null);
   const [selectedStudent, setSelectedStudent] = useState(null);
   const [loading, setLoading] = useState(false)

   const allLessons = [
      ...processLessons(teacher.students, "Regular", teacher.googleMeetLink),
      ...processLessons(teacher.trialLessons, "Trial", teacher.googleMeetLink),
      ...processLessons(reschedules, "Reschedule", teacher.googleMeetLink),
   ];
   const handleLessonClick = (lesson) => {
      setSelectedLesson(lesson);
   };
   const resheduleLesson = (day, time) => {
      setRescheduleModal(true)
      const dayName = days[day];
      const timeString = time.toString().padStart(2, '0') + ":00"; 
      setSelectedDayTime({day: dayName, time: timeString})
   };
   const submitReschedule = () => {
      setLoading(true)
      axios.post(`lesson-reschedule/${selectedStudent}/${teacher._id}`, { lessonReschedule: selectedDayTime })
        .then(() => {
           setSelectedStudent('')
           setSelectedCell(null)
           setSelectedDayTime({ day: "", time: "" })
           setRescheduleSubmited(true)
           setRescheduleModal(false)
           if(!isAdmin){
            axios.post(`teacher/rescheduled/${teacher._id}/${selectedStudent}/reschedule`, { lessonReschedule: selectedDayTime })
           }
           
        })
        .catch(() => console.log('Щось пішло не так'))
        .finally(() => setLoading(false))
   }
  return (
    <>
      <ScheduleGrid
        daysOfWeek={daysOfWeek}
        lessons={allLessons}
        handleLessonClick={handleLessonClick}
        resheduleLesson={resheduleLesson}
        selectedCell={selectedCell} 
        setSelectedCell={setSelectedCell} 
        isReschedule={rescheduleModal}
      />
      {rescheduleModal && (
        <Modal
          lesson={selectedLesson}
          onClose={handleCloseModal}
          joinedLesson={joinedLesson}
          isReschedule
          students={teacher.students}
          setSelectedStudent={setSelectedStudent}
          submitReschedule={submitReschedule}
          selectedStudent={selectedStudent}
          setSelectedCell={setSelectedCell} 
          loading={loading}
        />
      )}
    </>
  )
};

export default RescheduleTeacherBlock;
