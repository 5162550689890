import styled from "styled-components";

export const DatePickers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  
  & > div {
    flex: 1;
    margin: 0 10px;
    text-align: center;
    
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-family: Raleway;
    font-weight: bold;
    font-size: 18px;
    color: #000;
  }

  .react-datepicker-wrapper {
    width: 100%;
    
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #A09EFF;
    border-radius: 5px;
    text-align: center;
    transition: border-color 0.3s ease-in-out;
    cursor: pointer;
    &:focus {
      border-color: #000;
      outline: none;
    },
    
  }

  .react-datepicker {
    border: 2px solid #A09EFF;
    border-radius: 5px;
    
  }

  .react-datepicker__header {
    background-color: #A09EFF;
    color: white;
    border-radius: 2px;
  }

  .react-datepicker__day--selected {
    background-color: #A09EFF;
  }
`;

export const Button = styled.button`
 font-family: Raleway;
  padding: 10px 20px;
  background-color: #A09EFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;;
  }
`;