export function getCurrentLevel(lessonsCompleted, levels) {
   for (let i = levels.length - 1; i >= 0; i--) {
     if (lessonsCompleted >= levels[i].value) {
       return levels[i];
     }
   }
   return levels[0];
 }
 
export function getNextLevel(currentLevel, levels) {
   const currentIndex = levels.findIndex(level => level.value === currentLevel.value);
   return levels[currentIndex + 1] || currentLevel;
 } 