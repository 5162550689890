import styled from "styled-components";

export const Wrapper = styled.div`
   padding: 15px 20px 0px 0px;
   
`
export const Container = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  background-color: #f1f3f5;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const TeacherInfo = styled.p`
  font-size: 16px;
  color: #333333;
  margin: 0;
`;

export const SalaryInfo = styled.p`
  font-size: 14px;
  color: #777777;
  margin: 0;
`;

export const Button = styled.button`
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;