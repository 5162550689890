export const teacherLevels = [
   {name: 'Новачок викладання' , value: 0},
   {name: 'Молодий вчитель' , value: 10},
   {name: 'Вчитель-початківець' , value: 20},
   {name: 'асистент викладача' , value: 30},
   {name: 'Викладач-стажист' , value: 40},
   {name: 'Молодший викладач' , value: 50},
   {name: 'Вчитель' , value: 60},
   {name: 'Досвідчений вчитель' , value: 70},
   {name: 'Майстер викладання' , value: 80},
   {name: 'Профі викладання' , value: 90},
   {name: 'Експерт навчання' , value: 100},
   {name: 'Наставник учнів' , value: 120},
   {name: 'Інструктор англійської' , value: 120},
   {name: 'Куратор уроків' , value: 130},
   {name: 'Педагог-професіонал' , value: 140},
   {name: 'Професор англійської' , value: 150},
   {name: 'Академік викладання' , value: 160},
   {name: 'Великий учитель' , value: 170},
   {name: 'Мовний маестро' , value: 180},
   {name: 'Найкращий вчитель у світі' , value: 190},
]
