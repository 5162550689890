import styled from 'styled-components';

export const JoinButton = styled.button`
  
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  background: #A09EFF;
  transition: 0.3s;
  font-family: Raleway;
  font-weight: 500;
  &:hover {
    opacity: 0.9;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

export const studentInfo = styled.p`
   font-family: Raleway;
   font-weight: 500;
   text-align: left;
   &:not(:last-child){
      margin-bottom:20px;
   }
`

export const CancelLesson = styled.p`
  font-weight: 400;
  color: #f2bcbc;
  margin-top: 20px;
  text-align: end;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: red;
  }
`