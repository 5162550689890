import React, { useState, useEffect } from "react";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import * as styled from "./Homework.styled";
import Loader from "../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import {
  fetchAllLessons,
  fetchLastLesson,
} from "../../../helpers/homework/homework";
import HomeworkCard from "../../../components/HomeworkCard/HomeworkCard";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import HomeworkSubmission from "../../../components/HomeworkSubmission/HomeworkSubmission";
import axios from '../../../axios';

function Homework() {
  const [lastLesson, setLastLesson] = useState(null);
  const [allLessons, setAllLessons] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [homeworkOpen, setHomeworkOpen] = useState(false);
  const [lessonId, setLessonId] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const { data } = useSelector((state) => state.auth);

  useEffect(() => {
    const getLastLesson = async () => {
      try {
        const value = await fetchLastLesson(data._id);

        if (value.lessonType === "failed-by-student") {
          const allLessons = await fetchAllLessons(data._id);
          const successLessons = allLessons.filter(
            (res) => res.lessonType !== "failed-by-student"
          );
          setLastLesson(successLessons[0]);
        } else {
          setLastLesson(value);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getLastLesson();
  }, [data._id]);

  const handleToggleLessons = async () => {
    if (showAll) {
      setShowAll(false);
    } else {
      setLoading(true);
      try {
        const value = await fetchAllLessons(data._id);
        setAllLessons(value);
        setShowAll(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleHomeworkOpen = (id) => {
    setLessonId(id);
    setHomeworkOpen(prev => !prev);
    setSubmissionSuccess(false);  
    setSubmissionError(false); 
  };

  const submitHomework = async (text) => {
    setLoading(true);
    try {
      await axios.post(`marked-lessons/add-homework/${lessonId}`, { homework: text });
      setSubmissionSuccess(true);
      setSubmissionError(false);
    } catch (error) {
      setSubmissionError(true);
      setSubmissionSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSuccessMessage = () => {
    setSubmissionSuccess(false);
    setHomeworkOpen(false);
  };

  const handleCloseErrorMessage = () => {
    setSubmissionError(false);
    setHomeworkOpen(false);
  };

  const getHomeworkText = async (lessonId) => {
    const {data} = await axios.get(`marked-lessons/lesson/${lessonId}`);
    return data.lesson?.homeworkText;
  };

  return (
    <PagesWrapper>
      <TopPageTitle value={"Домашнє завдання"} />
      {loading ? (
        <Loader />
      ) : lastLesson ? (
        <styled.Content>
          {homeworkOpen ? (
            showAll
              ? allLessons
                .filter((lesson) => lesson._id === lessonId)
                .map((lesson) => (
                  <HomeworkCard
                    key={lesson._id}
                    lessonId={lesson._id}
                    setHomeworkOpen={handleHomeworkOpen}
                    teacherName={lesson.teacherName}
                    lessonDate={lesson.lessonDate}
                    comment={lesson.comment}
                    url={lesson.lesson}
                    lessonName={lesson.lessonName}
                    lessonType={lesson.lessonType}
                    setLessonId={setLessonId}
                    getHomeworkText={getHomeworkText}
                    homeworkOpen={homeworkOpen}
                  />
                ))
              : lastLesson && (
                  <HomeworkCard
                    key={lastLesson._id}
                    lessonId={lastLesson._id}
                    setHomeworkOpen={handleHomeworkOpen}
                    teacherName={lastLesson.teacherName}
                    lessonDate={lastLesson.lessonDate}
                    comment={lastLesson.comment}
                    url={lastLesson.lesson}
                    lessonName={lastLesson.lessonName}
                    lessonType={lastLesson.lessonType}
                    setLessonId={setLessonId}
                    getHomeworkText={getHomeworkText}
                    homeworkOpen={homeworkOpen}
                  />
                )
          ) : (
            <>
              {showAll
                ? allLessons.map((lesson) => (
                    <HomeworkCard
                      key={lesson._id}
                      lessonId={lesson._id}
                      setHomeworkOpen={handleHomeworkOpen}
                      teacherName={lesson.teacherName}
                      lessonDate={lesson.lessonDate}
                      comment={lesson.comment}
                      url={lesson.lesson}
                      lessonName={lesson.lessonName}
                      homeworkOpen={homeworkOpen}
                      lessonType={lesson.lessonType}
                      setLessonId={setLessonId}
                      getHomeworkText={getHomeworkText}
                    />
                  ))
                : lastLesson && (
                    <HomeworkCard
                      key={lastLesson._id}
                      lessonId={lastLesson._id}
                      setHomeworkOpen={handleHomeworkOpen}
                      teacherName={lastLesson.teacherName}
                      lessonDate={lastLesson.lessonDate}
                      comment={lastLesson.comment}
                      url={lastLesson.lesson}
                      lessonName={lastLesson.lessonName}
                      lessonType={lastLesson.lessonType}
                      setLessonId={setLessonId}
                      getHomeworkText={getHomeworkText}
                      homeworkOpen={homeworkOpen}
                    />
                  )}
              <styled.Button onClick={handleToggleLessons}>
                {showAll ? "Показати останній урок" : "Показати більше"}
              </styled.Button>
            </>
          )}
          {homeworkOpen && !submissionSuccess && !submissionError && (
            <HomeworkSubmission onSubmit={submitHomework} lessonId={lessonId} getHomeworkText={getHomeworkText} />
          )}
          {homeworkOpen && submissionSuccess && (
            <styled.SubmissionSuccess>
              <styled.CloseIcon onClick={handleCloseSuccessMessage}>&times;</styled.CloseIcon>
              <p>Ваше домашнє завдання було успішно відправлено на перевірку. Вчитель вже перевіряє його.</p>
            </styled.SubmissionSuccess>
          )}
          {homeworkOpen && submissionError && (
            <styled.SubmissionError>
              <styled.CloseIcon onClick={handleCloseErrorMessage}>&times;</styled.CloseIcon>
              <h2>Помилка при відправці ДЗ</h2>
              <p>Сталася помилка при відправці вашого домашнього завдання. Будь ласка, спробуйте ще раз пізніше.</p>
            </styled.SubmissionError>
          )}
        </styled.Content>
      ) : (
        <p>Поки що без ДЗ 🤗</p>
      )}
    </PagesWrapper>
  );
}

export default Homework;

