import * as styled from "./PageTitle.styled.jsx";
import { ReactSVG } from "react-svg";
import logo from "../../assets/icons/logo.svg";
import { UseMediaQuery } from "../../hooks/UseMediaQuery.js";
import HamburgerIcon from '../../components/HamburgerIcon/HamburgerIcon'

function PageTitle({ pageTitle, notAdmin, setShow }) {
  const { isMobile } = UseMediaQuery();
  

  return !isMobile ? (
    <styled.Wrapper notAdmin={notAdmin}>
      <ReactSVG src={logo} className="page-title-icon" />

      <styled.PageTitle>{pageTitle}</styled.PageTitle>
    </styled.Wrapper>
  ) : (
    <styled.Wrapper notAdmin={notAdmin}>
      <styled.TopWrapper>
         <ReactSVG src={logo} className="page-title-icon" />
         <HamburgerIcon setShow={setShow}/>
      </styled.TopWrapper>
      
    </styled.Wrapper>
  );
}

export default PageTitle;
