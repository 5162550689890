import styled from 'styled-components';

export const Container = styled.div`
   display: flex;
   flex-direction: column;
   padding: 20px;
   background-color: #f9f9f9;
   border-radius: 8px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   max-height: 150px;
   overflow-y: auto;
`;

export const DayRow = styled.div`
   display: flex;
   align-items: center;
   margin-bottom: 10px;
`;

export const Checkbox = styled.input`
   margin-right: 10px;
   border: 1px solid black;
   width: 15px;
   height: 15px;
   cursor: pointer;
   &:checked{
      background-color: black;
   }
`;

export const DayLabel = styled.label`
   flex: 1;
   font-size: 16px;
   color: #333;
`;

export const TimeInput = styled.input`
   flex: 1;
   font-size: 16px;
   padding: 5px;
   border: 1px solid #ccc;
   border-radius: 4px;
   background-color: #fff;
`;