import styled from "styled-components";

export const PagesWrapper = styled.div`
  padding: 20px 20px 150px 20px;

  @media (min-width: 768px){
   padding: 20px;
  };
  @media (min-width: 1280px){
    padding: 25px;
   };

   
   @media (max-width: 1280px) {
      ${props => props.openItem && `
         width: 100%;
         position: relative;
         overflow: hidden;
         &::before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5); 
            z-index: 5; 
            
         }
      `}
   }
`;
