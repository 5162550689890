import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;



export const Wrapper = styled.div`
  padding: 20px;
  font-family: 'Raleway', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  word-wrap: break-word;
`;



export const Button = styled.button`
  background-color: #F1F1F1;
  padding: 10px 50px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #8077776E;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  align-self: center;
  animation: ${fadeIn} 0.5s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }
`;


export const SubmissionSuccess = styled.div`
  margin-top: 50px;
  text-align: center;
  padding: 20px;
  background: linear-gradient(90deg, #D2D1FF 0%, #FFE1A2 100%);
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;


  p {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: #333;
    margin-top: 10px;

    @media (max-width: 1280px) {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  &::before {
    content: '🎉';
    font-size: 50px;
    display: block;
    margin-bottom: 20px;
    animation: bounce 1s infinite;

    @media (max-width: 1280px) {
      font-size: 40px;
    }

    @media (max-width: 768px) {
      font-size: 30px;
    }

    @media (max-width: 480px) {
      font-size: 25px;
    }
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`;

export const SubmissionError = styled.div`
  margin-top: 50px;
  text-align: center;
  padding: 20px;
  background: linear-gradient(90deg, #FFD1D1 0%, #FFA2A2 100%);
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  h2 {
    font-family: Raleway;
    font-size: 28px;
    font-weight: 700;
    color: #FF0000;
    margin: 0;

    @media (max-width: 1280px) {
      font-size: 24px;
    }

    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  p {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-top: 10px;

    @media (max-width: 1280px) {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  &::before {
    content: '⚠️';
    font-size: 50px;
    display: block;
    margin-bottom: 20px;
    animation: shake 1s infinite;

    @media (max-width: 1280px) {
      font-size: 40px;
    }

    @media (max-width: 768px) {
      font-size: 30px;
    }

    @media (max-width: 480px) {
      font-size: 25px;
    }
  }

  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
  }
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #000;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;
