import styled from "styled-components";
import { Link } from "react-router-dom";

export const List = styled.ul``;

export const Item = styled.li`
  transition: 0.3s;
  cursor: ${(props) => (props.isClosed ? 'default' : 'pointer')};
  opacity: ${(props) => (props.isClosed ? 0.5 : 1)};
  ${(props) =>
    props.isClosed
      ? `
        display: flex;
        align-items: center;
        pointer-events: none;
        background-color: #f8d7da;
        color: #721c24;
      `
      : `
        &:hover {
          background-color: ${props.currentroute ? '' : '#D2D1FF'};
        };
        background-color: ${props.currentroute ? '#A09EFF' : ''};
      `}
`;
export const ClosedIcon = styled.span`
  
  font-size: 1.2em;
  color: #721c24;
`;
export const StyledLink = styled(Link)`
  font-family: Raleway;
  font-size: 22px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
  width: 90%;
  padding: 20px 0px 20px 30px;
`;
