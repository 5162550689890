import * as styled from "./GeneralDropDown.styled"
import arrowIcon from '../../assets/icons/arrow.svg'
import lightbulbIcon from '../../assets/icons/lightbulb.svg'
function GeneralDropDown({setOpen, open, title, withoutMargin}){

  return (
   <styled.DropDown onClick={() => setOpen(prev => !prev)} open={open} withoutMargin={withoutMargin}>
      <styled.BulbIcon src={lightbulbIcon} height={'25px'}/>
      <styled.DropDownTitle>{title}</styled.DropDownTitle>
      <styled.DropDownIcon src={arrowIcon} open={open}/>
   </styled.DropDown>
  )
};

export default GeneralDropDown;
