import styled from "styled-components";
import Lottie from 'react-lottie';

export const Wrapper = styled.div`
 @media (max-width: 1280px) {
   display: flex;
   width: 100%;
 }
`

export const Loader = styled(Lottie).attrs(props => ({

}))``;