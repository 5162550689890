import styled from "styled-components";

export const Wrapper = styled.div`
   padding: 15px 20px 0px 0px;
   width: 100%;
`
export const TopWrapper = styled.div`
   margin-bottom: 45px;
`
export const Title = styled.p`
   font-family: Raleway;
   font-size: 22px;
   font-weight: 700;
   line-height: 24.2px;
`

export const List = styled.ul`
   display: flex;
   flex-wrap: wrap;
   gap: 25px;
`
export const Item = styled.li`
   padding: 15px 0px;
   background: #F1F1F1;
   box-shadow: 0px 4px 4px 0px #00000040;
   flex-basis: calc(100% / 3 - 25px);
   cursor: pointer;
   @media (min-width: 768px) {
      flex-basis: calc(100% / 4 - 25px);
   }

   &:hover{
      opacity: 0.75;
   }
`
export const AddStudentItem = styled(Item)`
   background: #FFE1A2;
   
`


export const ItemTitle = styled.p`
   font-family: Raleway;
   font-size: 22px;
   font-weight: 700;
   line-height: 24.2px;

   text-align: center;
`