import styled from "styled-components";

export const Button = styled.button`

   background-color: #F1F1F1;
   padding: 10px 50px;
   border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
   cursor: pointer;

   font-family: Raleway;
   font-size: 11px;
   font-weight: 700;
   line-height: 12.1px;

   &:hover{
      background-color: #EFECEC;
   }
`