export const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];
export const dayMapping = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6
};

export const processLessons = (lessons, type, googleMeetLink) => {
  if(lessons.length === 0) return []
  return lessons.flatMap(student => {
    if (!student.classSchedule && type !== 'Reschedule') {
      return [];
    }
    

    if(type === 'Reschedule') {
        const [hour, minute] = student.lessonReschedule.time.split(':').map(Number);
        return {
          day: dayMapping[student.lessonReschedule.day],
          hour,
          minute,
          name: type === 'Reschedule' ? student.studentName : student.name,
          time: student.lessonReschedule.time,
          ageGroup: student.ageGroup,
          googleMeetLink: googleMeetLink,
          englishLevel: student.englishLevel,
          studentId: student.student_id,
          type: student.type !== 'cancel' ? type === 'Reschedule' ? (student.type !== 'teacher-changed' ? 'Reschedule Lesson' : 'Substitute') : type : student.type,
        };
    }else {
      return student.classSchedule.map(schedule => {
        const [hour, minute] = schedule.time.split(':').map(Number);
        return {
          day: dayMapping[schedule.day],
          hour,
          minute,
          name: type === 'Reschedule' ? student.studentName : student.name,
          time: schedule.time,
          ageGroup: student.ageGroup,
          googleMeetLink: googleMeetLink,
          englishLevel: student.englishLevel,
          studentId: student.studentId,
          type: student.type !== 'cancel' ? type === 'Reschedule' ? (student.type !== 'teacher-changed' ? 'Reschedule Lesson' : 'Substitute') : type : student.type,
        };
      });
    }
    
  });
};