import { useNavigate } from 'react-router-dom'
import TopPageTitle from '../../../components/TopPageTitle/TopPageTitle.jsx'
import * as styled from './OurTeachers.styled.jsx'
import { useState, useEffect } from 'react'
import { getAllTeachers } from '../../../helpers/getAllTeachers.js'
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled.jsx'


function OurTeachers(){
   const [teachers, setTeachers] = useState([])
   const navigate = useNavigate()

   useEffect(() => {
      getAllTeachers().then(data => {
         setTeachers(data)
      })
   }, [])
   const teacherClickId = (id) => {
      navigate(`${id}`)
   }
   return (
      <PagesWrapper>
         <TopPageTitle value={'Наші вчителі'}/>
      
         <styled.List>
            <styled.AddTeacherItem onClick={() => navigate(`addteacher`)}>
               <styled.ItemTitle>+ Додати вчителя</styled.ItemTitle>
            </styled.AddTeacherItem>
            {teachers.map(({_id, teacherId, name}) => (
               <styled.Item key={_id} onClick={() => teacherClickId(_id)}>
                  <styled.ItemTitle>{`${name} - id(${teacherId})`}</styled.ItemTitle>
               </styled.Item>
            ))}
            
         </styled.List>

      </PagesWrapper>
   )
}

export default OurTeachers