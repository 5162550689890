import styled, {keyframes} from "styled-components";
const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%; 
  overflow: auto;
  
  @media (max-width: 768px){
   position: fixed;
   top: 0;
   left: 0;
   z-index: 100;
   transition: opacity 0.5s ease;
   opacity: ${props => (props.show ? '1' : '0')};
   animation: ${props => (props.show ? slideIn : slideOut)} 0.3s forwards;
   background-color: #fff;
   width: 100vw;
   box-sizing: border-box;
  };


  @media (min-width: 768px){
   min-width: 280px;
   border-right: 5px solid #b4b4b4;
  };


  @media (min-width: 1280px){
   min-width: 350px;
  }
`;

export const BtnWrapper = styled.div`
  align-self: center; 
  
  margin: 40px 0px; 

  @media (max-width: 768px){
   margin: 70px 0px 0px 0px; 
  }
`;


export const AvatarWrapper = styled.div`

   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 40px;
`
export const HelloTitle = styled.p`
   font-family: Raleway;
   font-size: 15px;
   font-weight: 300;
   line-height: 16.5px;
   margin-bottom: 5px;
`

export const UserName = styled.p`
   font-family: Raleway;
   font-size: 25px;
   font-weight: 900;
   line-height: 27.5px;
   color: #3D3D3D;
`