import { ReactSVG } from "react-svg";
import styled from "styled-components";

export const Wrapper = styled.div`
 padding-bottom: 40px;
 @media (min-width: 1280px) {
   display: flex;
   flex-direction: row-reverse;
   align-items: flex-start;
 }
`
export const DropDownList = styled.ul`
   max-width: 610px;
   width: 100%;
   @media (max-width: 1280px) {
      margin-top: 60px;
      
   };
   @media (min-width: 1280px) {
      margin-right: 30px;   
   } 
`

export const DropDownItem = styled.li`
    width: 100%;
    &:not(:last-child){
     margin-bottom: 30px;
    }
`

export const DropDownElWrapper = styled.div`
   width: 100%;
   margin-bottom: 40px;
`
export const Video = styled.iframe`
   width: 100%;
   border-radius: 2px;
   height: 300px;
   @media (min-width: 768px){
    height: 400px;
   }
`

export const LampIcon = styled(ReactSVG)`
   position: absolute;
   right: 5%;
   bottom: 10%;
   width: 250px;
   height: 300px;
`

export const RightPartWrapper = styled.div`
   display: flex;
   flex-direction: column;
   margin: 0 auto;

   @media (max-width: 1280px) {
      margin-top: 45px;
   };

   @media (min-width: 1280px) {
      margin: 0px 0px 0px auto;
      ${props => props.isTrial ? `
          width: 100%;
         ` : `
         max-width: 360px;
      `}
     
     
   }
`