import * as styled from './CurrentStep.styled.jsx'


function CurrentStep ({currentStep}) {
   const steps = [
      {step: 1},
      {step: 2},
      {step: 3}
   ]
   const lastStep = steps[steps.length - 1].step
   return(
      <styled.Wrapper>
            <styled.List>
               {steps.map(({step}) => (
                  <styled.Item>

                     <styled.ItemWrapper iscurrentstep={currentStep === step}>
                        <styled.StepTitle>
                           {step}
                        </styled.StepTitle>
                     </styled.ItemWrapper>

                    {step !== lastStep && <styled.Border></styled.Border>}
                  </styled.Item>
                  
               ))}
            </styled.List>
      </styled.Wrapper>
   )
}


export default CurrentStep