import { useState, useEffect } from 'react';
import TopPageTitle from '../../../components/TopPageTitle/TopPageTitle'
import * as styled from './Finance.styled'
import axios from '../../../axios'
import { useSelector } from 'react-redux';
import DateSalaryPicker from '../../../components/DatePicker/DatePicker';
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled'
function formatDate(date) {
   return date.toISOString().split('T')[0];
}
function Finance() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [totalSalary, setTotalSalary] = useState(0);
  const [lessons, setLessons] = useState([]);
  const { data } = useSelector((state) => state.auth);

  const fetchSalaryData = async () => {
    try {
      const response = await axios.get(`/teachers/${data._id}/salary`, {
         params: {
           startDate: formatDate(startDate),
           endDate: formatDate(endDate)
         }
       });
       setTotalSalary(response.data.totalSalary);
       setLessons(response.data.lessons);
     
    } catch (error) {
      console.error('Error fetching salary data:', error);
    }
  };

  useEffect(() => {

    fetchSalaryData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
   <PagesWrapper>
   <TopPageTitle value={"Фінанси"} />
   <DateSalaryPicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} fetchSalaryData={fetchSalaryData}/>
   <styled.Balance>Поточний зарплатний баланс: {totalSalary} грн</styled.Balance>
   <styled.Lessons>
     {lessons.map(lesson => {
      const studentId = data.students.find(value => value._id === lesson.studentId)?.studentId
      return (
       <styled.Lesson key={lesson._id}>
         <styled.LessonInfo> {lesson.lessonType !== 'failed-by-student' ? lesson.lessonType === 'Trial' ? 'Пробний урок відбувся з:' : "Урок відбувся з:" : 'Урок не відбувся з провини учня:'} {lesson.studentName ? lesson.studentName : 'User'} {studentId ? `id(${studentId})` : ''}</styled.LessonInfo>
         <styled.LessonInfo>Дата уроку: {new Date(lesson.lessonDate).toLocaleDateString()}</styled.LessonInfo>
         <styled.LessonInfo>Заробіток: {lesson.salary} грн</styled.LessonInfo>
         
       </styled.Lesson>
     )})}
   </styled.Lessons>
 </PagesWrapper>
  );
}

export default Finance

