import { FaTimes } from "react-icons/fa";
import styled from "styled-components";

export const Wrapper = styled.div`
   padding: 15px 0px 0px 0px;
   font-family: Raleway;
   
   @media (max-width: 1280px) {
      padding: 15px 20px;
      width: 100%;
      position: relative;

      ${props => props.openItem && `
       overflow: hidden;
         &::before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5); 
            z-index: 5; 
            
         }
      `}
   }
   
`

export const List = styled.ul`
   position: relative;
   @media (max-width: 767px){
      width: 100%;
   }
`
export const Item = styled.li`
   // position: relative;
   display: flex;
   &:not(:last-child){
      margin-bottom: 20px;
   };

   @media (max-width: 767px){
      width: 100%;
   }
`
export const BoxWrapper = styled.div`
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   background-color: ${props =>  !props.isCanceled ? !props.isItemOpen ? !props.isReschedule ? props.isSchedule ? "#FFE1A2" : '#F1F1F1' : '#A09EFF' : '#e0ffe0' : '#ff7f7f'};
   padding: 15px 0px;
   width: 100%;
   box-shadow: 0px 4px 4px 0px #00000040;
   border-radius: 15px;
   transition: 0.3s;
   ${props => props.isReschedule && !props.isCanceled ? `
      cursor: pointer;
      &:hover{
         background-color: #D2D1FF;
      }
   ` : ''};
   ${props => props.isSchedule && !props.isReschedule && !props.isCanceled ? `
      cursor: pointer;
      &:hover{
         background-color: #FFC857;
      }
   ` : ''};
    

  
   @media (min-width: 767px){
      max-width: 320px;
   }
`



export const Day = styled.p`
   font-family: Raleway;
   font-size: 22px;
   font-weight: 700;
   line-height: 24.2px;
   margin-bottom: 5px;
`

export const Time = styled.p`

   font-size: 15px;
   font-weight: 500;
   line-height: 16.5px;
`

export const ConnectWrapper = styled.div`
   position: absolute;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 40px 50px 15px 50px;
   background-color: #FAFAFA;
   box-shadow: 0px 4px 4px 0px #00000040;
   border-radius: 15px;
   z-index: 10;
   transition: opacity 0.3s, visibility 0.2s;
   opacity: ${props => (props.isItemOpen ? 1 : 0)};
   visibility: ${props => (props.isItemOpen ? 'visible' : 'hidden')};
   pointer-events: ${props => (props.isItemOpen ? 'auto' : 'none')};

   @media (max-width: 1280px){
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
   };
   @media (min-width: 1280px){
      top: 50%;
      left: 55%;
      transform: translate(-0%, -50%);
   }
`

export const CloseButton = styled(FaTimes)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  color: #000;
   transition: 0.3s;
  &:hover {
    color: #A09EFF;
  }
`;
export const connectLink = styled.a`
   padding: 10px 15px;
   background-color: #A09EFF;
   border-radius: 10px;
   margin-bottom: 30px;
   font-family: Raleway;
   font-size: 12px;
   font-weight: 700;
   line-height: 24.2px;
   cursor:pointer; 
   transition: 0.3s;
   &:hover{
      opacity: 0.9;
   };
   @media (max-width: 767px){
      text-align: center;
   };

   @media (min-width: 767px){
      padding: 20px 35px;
      font-size: 18px;
      margin-bottom: 70px;
   }
`

export const cancelLesson = styled.button`
   font-family: Raleway;
   text-align: center;
   cursor: pointer;
   padding: 10px;
   background-color: #F1F1F1;
   border-radius: 10px;
   box-shadow: 0px 4px 4px 0px #00000040;
   font-size: 8px;
   font-weight: 400;
   transition: 0.3s;
   &:hover{
      box-shadow: 0px 4px 10px 0px #00000040;
   };

   @media (min-width: 767px){
      font-size: 11px;
   }
`
export const FormWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;


export const FormTitle = styled.p`
   font-family: Raleway;
  font-size: 10px;
  margin-bottom: 20px;
  @media (max-width: 767px){
   text-align: center;
 };
`


export const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`;





export const Input = styled.input.attrs({ type: 'radio' })`
   width: 14px;
   height: 14px;
   cursor: pointer;
   border: 1px solid #ccc;
   background-color: ${props => props.isChecked ? '#A09EFF' : "white"};
   appearance: none;
   -webkit-appearance: none;
   -moz-appearance: none;
   outline: none;
   transition: background-color 0.3s, border-color 0.3s;



   &:hover {
   border-color: #A09EFF;
   }
`;


export const Label = styled.label`
   font-family: Raleway;
  position: relative;
  display: inline-block;
  
  margin-left: 10px;
  @media (max-width: 767px){
   font-size: 10px;
  }
`;


export const SubmitButton = styled.button`
   font-family: Raleway;
  padding: 10px 20px;
  background-color: #A09EFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
  margin-top: 20px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
`;

export const RescheduleText = styled.p`
  position: absolute;
  right: 10px;
  top: 10px;
  font-family: Raleway;
  padding: 2px 2px;
  background-color: ${props => props.isCanceled ? '#ff7f7f' : '#A09EFF'};
  box-shadow: 0px 2px 2px 0px #000;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 7px;

`