import axios from '../../axios'

export const cancelLesson = async (id, type, selectedLesson) => {
   try {
      const response = await axios.post(`/students/cancel-lesson/${id}/${type}`, {selectedLesson});

      return response;
    } catch (error) {

      console.error('Error cancel lesson', error);

    }
}