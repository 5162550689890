export const teacherTextFields = [
   { name: "name", inputName: "Ім'я" },
   { name: "teacherId", inputName: 'Id вчителя' },
   { name: "phoneNumber", inputName: 'Номер телефону' },
   { name: "googleMeetLink", inputName: 'Google Meet посилання' },
   { name: "email", inputName: 'Email' },
   { name: "password", inputName: 'Пароль' },
]

export const ageGroups = [
   {id: 1, name: "Kids 6-12", value: "kids"},
   {id: 2, name: "Teenagers 13-17", value: "teenagers"},
   {id: 3, name: "Adults", value: "adults"}
]