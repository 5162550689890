import * as styled from './LeftPanel.styled.jsx'
import PageTitle from '../PageTitle/PageTitle.jsx'
import NavBar from '../NavBar/NavBar.jsx'
import LogoutBtn from '../LogoutBtn/LogoutBtn.jsx'
import { useMenu } from '../../context/MenuContext.js'
import AvatarBlock from '../AvatarBlock/AvatarBlock.jsx'
import { UseMediaQuery } from '../../hooks/UseMediaQuery.js'

function LeftPanel({navList , pageTitle, currentRoute, notAdmin, userName }) {
   const { showMenu, setShowMenu } = useMenu();
   const {isMobile} = UseMediaQuery()
   return (
      <styled.Wrapper show={showMenu}>
        <PageTitle pageTitle={pageTitle} notAdmin={notAdmin} />
         {(!isMobile && notAdmin) && (
            <AvatarBlock userName={userName}/>
         )}
         <NavBar navList={navList} currentRoute={currentRoute} setShowMenu={setShowMenu}/>

         <styled.BtnWrapper>
            <LogoutBtn/>
         </styled.BtnWrapper>
      </styled.Wrapper>
   ) 
}

export default LeftPanel