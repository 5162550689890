import styled from 'styled-components';
import { CommonBtn } from "../../../styles/components/Button.styled";
export const ReschedulesWrapper = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 100px;

`

export const ReschedulesBtn = styled(CommonBtn)`
   background-color: grey !important;
   margin: 0 auto !important;
`
export const List = styled.ul`
   margin-top: 30px;
`

export const Item = styled.li`
   position: relative;
   border: 1px solid grey;
   padding: 10px;
   &:not(:last-child){
      margin-bottom: 30px;
   }
`

export const Text = styled.p`
   &:not(:last-child){
      margin-bottom: 10px;
      border-bottom: 1px solid grey;
      padding-bottom: 5px;
   }
`
export const Date = styled.div`
   position: absolute;
   right: -10px;
   top: -10px;
   background-color: #A09EFF;
   padding: 5px;
   border-radius: 5px;
`
export const DateTitle = styled.p`

`
export const RemoveBtn = styled.button`
   position: absolute;
   right: 0px;
   bottom: 0px;
   background-color: #ff7f7f;
   padding: 10px;
   border-radius: 5px;
   font-size: 10px;
`