// Homework.styled.js

import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  padding: 20px;
  font-family: 'Raleway', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  word-wrap: break-word;
`;

export const Button = styled.button`
  background-color: #F1F1F1;
  padding: 10px 50px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #8077776E;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  align-self: center;
  animation: ${fadeIn} 0.5s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const SubmissionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 400px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #ccc;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  transition: border-color 0.3s;
  box-shadow: 0px 1px 1px 0px #000;
  resize: none;
  &:focus {
    border-color: #A09EFF;
    outline: none;
  }
`;

export const SubmitButton = styled.button`
  font-family: Raleway;
  font-size: 17px;
  font-weight: 700;
  line-height: 18.7px;
  color: #000000;
  max-width: 200px;
  margin: 50px auto 0px;
  border-radius: 15px;
  background: linear-gradient(90deg, #D2D1FF 0%, #FFE1A2 100%);
  padding: 10px 35px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;
