import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-height: 300px; 
  overflow-y: auto; 
  margin-bottom: 40px;
  border-left: 4px solid #E1E0FF;
  border-right: 4px solid #E1E0FF;
  border-bottom: 4px solid #E1E0FF;
  padding: 20px 20px 20px 20px;
  border-radius: 0px 0px 15px 15px;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const Item = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Text = styled.p`
  font-family: Raleway;
  font-size: 13px;
  font-weight: 500;
  line-height: 14.3px;
`;

export const TextBold = styled.span`
  font-weight: 700;
`;
