import { useState } from "react";
import * as styled from "./StudentsDropDown.styled"

function StudentsDropDown({students, getLessons, setSelectedStudent, selectedStudent, placeholder}){
  const [studentFilter, setStudentFilter] = useState("");

  return (
   <styled.TopWrapper>
   <styled.DropdownWrapper>
     <styled.Input
       type="text"
       placeholder={`Виберіть або впишіть ${placeholder ? placeholder : 'учня'}`}
       value={studentFilter}
       onChange={(e) => setStudentFilter(e.target.value)}
     />
     {students
       .filter((student) =>
         student.name.toLowerCase().includes(studentFilter.toLowerCase())
       )
       .map((student) => (
         <styled.Select
           key={student._id}
           onClick={() => {
               if(getLessons){
                  getLessons(student._id);
               }
             
             setSelectedStudent(student._id);
           }}
           selected={student._id === selectedStudent}
         >
           <styled.Option>
             {student.name} - id:{student.studentId || student.teacherId }
           </styled.Option>
         </styled.Select>
       ))}
   </styled.DropdownWrapper>
 </styled.TopWrapper>
  )
};

export default StudentsDropDown;
