import { useNavigate } from 'react-router-dom'
import { CommonBtn } from '../../styles/components/Button.styled'
import * as styled from './AddedSuccessfully.styled'


function AddedSuccessfully({isSuccess, successTitle, errorTitle}) {
   const navigate = useNavigate()
   return(
      <div>
         {isSuccess ? <p>{successTitle}</p> : <p>{errorTitle}</p>}
         <CommonBtn onClick={() => navigate('/admin/ourstudents')}>До наших учнів</CommonBtn>
      </div>
   )
}

export default AddedSuccessfully