import { useSelector } from "react-redux";
import { PagesWrapper } from "../../styles/components/PagesWrapper.styled";
import AvatarBlock from "../AvatarBlock/AvatarBlock";
import TopPageTitle from "../TopPageTitle/TopPageTitle";
import { UseMediaQuery } from "../../hooks/UseMediaQuery";
import * as styled from "./GeneralPage.styled";
import ProgressBlock from "./ProgressBlock/ProgressBlock";
import FeatureBlock from "./FeatureBlock/FeatureBlock";
import { useState } from "react";
import GeneralDropDown from "../GeneralDropDown/GeneralDropDown";
import AdviceDropDown from "../AdviceDropDown/AdviceDropDown";
import { adviceStudent, newFeatureStudent, newFeatureTitleStudent, rules } from "../../helpers/generalStudentTexts/generalStudentTexts";
import {studentLevels} from "../../helpers/lessonsLevels/studentLevels"
import { teacherLevels } from "../../helpers/lessonsLevels/teacherLevels";
import { adviceTeacher, newFeatureTeacher, newFeatureTitleTeacher, rulesTeacher, perfectTeacher } from "../../helpers/generalTeacherTexts/generalTeacherTexts";
import PerfectTeacherDropDown from "../PerfectTeacherDropDown/PerfectTeacherDropDown";



function GeneralPage({ isStudent, isTeacher, isTrial }) {
  const { data } = useSelector((state) => state.auth);
  const { isMobile } = UseMediaQuery();
  const [videoOpen, setVideoOpen] = useState(false);
  const [adviceOpen, setAdviceOpen] = useState(false);
  const [studentRulesOpen, setStudentRulesOpen] = useState(false);
  const [perfectTeacherOpen, setPerfectTeacherOpen] = useState(false);
  const [trialVideo, setTrialVideo] = useState(false);

  return (
    <PagesWrapper>
      <TopPageTitle value={"Головна"} />
      {isMobile && <AvatarBlock userName={data.name ? data.name : "No name"} />}
      <styled.Wrapper>
        {(isStudent || isTeacher || isTrial) && (
          <styled.RightPartWrapper isTrial={isTrial}>
            <ProgressBlock data={data} levels={isStudent || isTrial ? studentLevels : teacherLevels} isTrial={isTrial}/>
           {!isTrial && <FeatureBlock title={isStudent ? newFeatureTitleStudent : newFeatureTitleTeacher} list={isStudent ? newFeatureStudent : newFeatureTeacher}/>}
          </styled.RightPartWrapper>
          
          )}
        <styled.DropDownList>
         {!isTrial && <styled.DropDownItem>
            <GeneralDropDown
              setOpen={setVideoOpen}
              open={videoOpen}
              title={"Як користуватися платформою?"}
            />
            {videoOpen && (
              <styled.DropDownElWrapper>
                <styled.Video
                  src={isStudent ? "https://www.youtube.com/embed/lx_yH6rVh4w?si=4O_f7le5qcQ_l7wI" : isTeacher ? "https://www.youtube.com/embed/XPYaArtqpO0?si=qGxc9ZbzwV2emiFY" : ''}
                  title="Як користуватися платформою?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></styled.Video>
              </styled.DropDownElWrapper>
            )}
          </styled.DropDownItem>}
          {(isStudent || isTrial) && (
            <>
              <styled.DropDownItem>
                <GeneralDropDown
                  setOpen={setStudentRulesOpen}
                  open={studentRulesOpen}
                  title={!isTrial ? "Пригадати правила школи" : "Переглянути правила школи"}
                  withoutMargin
                />
                {studentRulesOpen && <AdviceDropDown texts={rules} />}
              </styled.DropDownItem>
              
              <styled.DropDownItem>
                <GeneralDropDown
                  setOpen={setAdviceOpen}
                  open={adviceOpen}
                  title={"Корисні поради для вивчення мови!"}
                  withoutMargin
                />
                {adviceOpen && <AdviceDropDown texts={adviceStudent} />}
              </styled.DropDownItem>
              
            </>
            
          )}
          {isTeacher && (
            <>
              <styled.DropDownItem>
                <GeneralDropDown
                  setOpen={setAdviceOpen}
                  open={adviceOpen}
                  title={"Корисні поради для викладання!"}
                  withoutMargin
                />
                {adviceOpen && <AdviceDropDown texts={adviceTeacher} />}
              </styled.DropDownItem>
              <styled.DropDownItem>
                <GeneralDropDown
                  setOpen={setStudentRulesOpen}
                  open={studentRulesOpen}
                  title={"Пригадати правила школи"}
                  withoutMargin
                />
                {studentRulesOpen && <AdviceDropDown texts={rulesTeacher} />}
              </styled.DropDownItem>
              <styled.DropDownItem>
                <GeneralDropDown
                  setOpen={setPerfectTeacherOpen}
                  open={perfectTeacherOpen}
                  title={"Perfect Teacher"}
                  withoutMargin
                />
                {perfectTeacherOpen && <PerfectTeacherDropDown links={perfectTeacher} />}
              </styled.DropDownItem>
              <GeneralDropDown
                setOpen={setTrialVideo}
                open={trialVideo}
                title={"Як провести WOW пробний?"}
               />
              {trialVideo && (
                <styled.DropDownElWrapper>
                  <styled.Video
                    src={'https://www.youtube.com/embed/7ubaa6JK4mE?si=GTUjE9oIVY4aIZuN'}
                    title="Як провести пробний урок, щоб він був WOW?"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></styled.Video>
                </styled.DropDownElWrapper>
            )}
            </>
            
          )}
        </styled.DropDownList>
      </styled.Wrapper>
    </PagesWrapper>
  );
}
export default GeneralPage;
