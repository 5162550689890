import TextInput from '../../TextInput/TextInput'
import * as styled from './ThirdStep.styled'

function ThirdStep({thirdStepFields, register}){
   return (
      <styled.Wrapper>
         {thirdStepFields.map(({ name, inputName }) => (
            <TextInput
               key={name}
               fieldName={inputName}
               name={name}
               register={register}
            />
         ))}
      </styled.Wrapper>
   )
}

export default ThirdStep