import { useNavigate, useParams } from 'react-router-dom'
import * as styled from './StudentsGroup.styled'
import { useEffect, useState } from 'react'
import { getAgeGroup } from '../../../helpers/getAgeGroup'
import TopPageTitle from '../../../components/TopPageTitle/TopPageTitle'
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled'

function StudentsGroup () {
   const [students, setStudents] = useState([])
   const navigate = useNavigate()
   const {value} = useParams()
   
   useEffect(() => {
      getAgeGroup(value).then(data => {
         setStudents(data.students)
      })

      
   }, [value])
   const studentClickId = (id) => {    
      navigate(`${id}`)
   }

   return (
      <PagesWrapper>
         <TopPageTitle value={value}/>
        
         <styled.List>
            <styled.AddStudentItem onClick={() => navigate(`addstudent`)}>
               <styled.ItemTitle>+ Додати учня</styled.ItemTitle>
            </styled.AddStudentItem>
            {students.map(({_id, studentId, name}) => (
               <styled.Item key={_id} onClick={() => studentClickId(_id)}>
                  <styled.ItemTitle>{`${name} - id(${studentId})`}</styled.ItemTitle>
               </styled.Item>
            ))}
            
         </styled.List>

      </PagesWrapper>
   )
}

export default StudentsGroup