import { useState } from "react";
import { getAllTeachers } from "../../helpers/getAllTeachers";
import * as styled from "./SelectTeacher.styled"

function SelectTeacher({setLoading, loading, setTeachers, teachers, setTeacherClick, teacherClick, handleClick, selectedTeacher, title}){
   const [filter, setFilter] = useState("");

   const handleFilterChange = (event) => {
      setFilter(event.target.value);
    };
   const handleTeacherClick = () => {
      if(teachers.length > 0) return
      setLoading(true); 
      getAllTeachers().then(value => {
        setTeachers(value);
        setLoading(false); 
      });
   };
   const filteredTeachers = teachers.filter((teacher) =>
      teacher.name.toLowerCase().includes(filter.toLowerCase()) ||
      teacher.teacherId.toLowerCase().includes(filter.toLowerCase()) 
   );
   if(teacherClick){
    handleTeacherClick()
   }
  return (
    <styled.Wrapper>
    <styled.InputName>{title ? title :'Ім’я вчителя'}</styled.InputName>
      <styled.SelectWrapper
        teacherClick={teacherClick}
        onClick={() => {
          setTeacherClick((prev) => !prev);
        }}
      >
        {selectedTeacher || "select"}
      </styled.SelectWrapper>
      {teacherClick && (
        <styled.TeacherWrapper>
          <styled.TeacherInput
            type="text"
            placeholder="Search by names or id..."
            value={filter}
            onChange={handleFilterChange}
          />
          {loading ? ( 
            <styled.Loader>Loading...</styled.Loader>
          ) : (
            <ul>
              {filteredTeachers.map(({ name, _id, teacherId }) => (
                <styled.TeacherItem
                  key={_id}
                  onClick={() => handleClick(_id, name)}
                >
                  <styled.TeacherName>{name} id:{teacherId}</styled.TeacherName>
                </styled.TeacherItem>
              ))}
            </ul>
           )} 
        </styled.TeacherWrapper>
        )}
    </styled.Wrapper>
   )
};

export default SelectTeacher;
