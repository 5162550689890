import { useState, useEffect } from 'react'
import * as styled from './StudentInfo.styled.jsx'
import { useNavigate, useParams } from 'react-router-dom'
import { getStudentInfo } from '../../../helpers/getStudentInfo.js'
import TopPageTitle from '../../../components/TopPageTitle/TopPageTitle.jsx'
import axios from '../../../axios'
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled.jsx'
import SelectTeacher from '../../../components/SelectTeacher/SelectTeacher.jsx'

// const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
// const twoWeeksAgo = Date.now() - 2 * 7 * 24 * 60 * 60 * 1000;
// const threeWeeksAgo = Date.now() - 3 * 7 * 24 * 60 * 60 * 1000;
// const oneMonthAgo = Date.now() - 30 * 24 * 60 * 60 * 1000;

function StudentInfo(){
   const [student, setStudent] = useState({});
   const [teacherClick, setTeacherClick] = useState(false);
   const [selectedTeacher, setSelectedTeacher] = useState("");
   const [teachers, setTeachers] = useState([]);
   const [loading, setLoading] = useState(false);
   const [isSuccessUpdate, setIsSuccessUpdate] = useState(null);
   const {value, id} = useParams();
   const navigate = useNavigate()
   useEffect(() => {
      getStudentInfo(value, id).then(({student}) => setStudent(student))
   }, [value, id])

   const handleRemove = (studentId, teacherId) => {
      axios.delete(`students/${studentId}/${teacherId}`)
      .then(() => {
         navigate('/admin/ourstudents')
      }).catch(err => console.error(err));
   }
  
   const updateTeacher = (newTeacherId, newTeacherName) => {
      setSelectedTeacher(newTeacherName)
      axios.put(`update-teacher/${student._id}/${student.teacherId}/${newTeacherId}`)
       .then(() => {
         setIsSuccessUpdate(true)
       })
       .catch((err) => {
         setIsSuccessUpdate(false)
         console.log(err)
       })
       .finally(() => {
         setTeacherClick(false)
       })
   }

   return (
      <PagesWrapper>
          <TopPageTitle value={student.name}/>
         <styled.List>
            <styled.Item>
               <styled.ItemTitle>Ім’я: {student.name} - id:{student.studentId}</styled.ItemTitle>
            </styled.Item>
            <styled.Item>
               <styled.ItemTitle>Група: {student.ageGroup}</styled.ItemTitle>
            </styled.Item>
            <styled.Item>
               <styled.ItemTitle>Рівень: {student.englishLevel}</styled.ItemTitle>
            </styled.Item>
            <styled.Item>
               <styled.EditWrapper>
                  <styled.ItemTitle>Викладач: {selectedTeacher.length <= 0 ? student.teacherName : selectedTeacher}</styled.ItemTitle>
                  <styled.Pen onClick={() => setTeacherClick((prev) => !prev)}/>
               </styled.EditWrapper>
              {teacherClick && <styled.SelectWrapper>
                  <SelectTeacher setLoading={setLoading} loading={loading} setTeachers={setTeachers} teachers={teachers} setTeacherClick={setTeacherClick} teacherClick={teacherClick} handleClick={updateTeacher} selectedTeacher={selectedTeacher} title={'Виберіть нового вчителя'}/>
               </styled.SelectWrapper>}
               {isSuccessUpdate && <p style={{color: 'green'}}>Вчителя успішно оновленно</p>}
               {isSuccessUpdate === false && <p style={{color: 'red'}}>Щось пішло не так ....</p>}
            </styled.Item>
            <styled.Item>
               <styled.ItemTitle>Розклад: </styled.ItemTitle>
               {student?.classSchedule?.map(({day, time}) => (
                   <styled.ItemTitle key={day}>{day}: {time}</styled.ItemTitle>
               ))}
              
            </styled.Item>
            <styled.Item>
               <styled.ItemTitle>Номер телефону: {student.phoneNumber}</styled.ItemTitle>
            </styled.Item>
            <styled.Item>
               <styled.ItemTitle>Пакет уроків: {student.lessonPackage}</styled.ItemTitle>
            </styled.Item>

            <styled.RemoveBtn onClick={() => handleRemove(student._id, student.teacherId)}>Видалити учня</styled.RemoveBtn>
         </styled.List>
      
      </PagesWrapper>
      
   )
}

export default StudentInfo