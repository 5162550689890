import styled from 'styled-components';


export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  background-color: #F3F3FC;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #9B9B9B40;
`;

export const Wrapper = styled.div`
  &:not(:last-child){
    margin-bottom: 30px;
  }
`

export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 10px;
  max-height: 300px; 
  overflow-y: auto; 
  border-left: 2px solid #A09EFF;
  border-right: 2px solid #A09EFF;
  border-bottom: 2px solid #A09EFF;
  border-radius: 0px 0px 15px 15px;
`;

export const Select = styled.div`
  padding: 15px 5px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#FFE1A2' : '#DBDAFF'};
  color: ${props => props.selected ? '#fff' : ''};
  transition: 0.4s;
  &:hover{
    background-color: ${props => props.selected ? '#FFE1A2' : '#D2D1FF'};
    color: #fff;
  }
  &:not(:last-child){
    margin-bottom: 10px;
    
  }
`;

export const Option = styled.p`
  font-family: Raleway;
  font-size: 13px;
  font-weight: 400;
  line-height: 11px;
  color: #0D0D0C;
`

export const Input = styled.input`
  font-family: Raleway;
  font-weight: 400;
  font-size: 10px;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  color: #8E8E8E;
  background-color: #F1F1F1;
  &::placeholder{
    font-size: 12px;
  }
`;

export const TextArea = styled.textarea`
  font-family: Raleway;
  padding: 25px 30px;
  box-shadow: 0px 4px 4px 0px #9B9B9B40;
  background-color: #fff;
  border-radius: 14px;
  resize: none;
  position: relative; 
  align-content: center;

  &::placeholder {
    font-family: Raleway;
    font-size: 17px;
    font-weight: 500;
    color: #B4B4B4;
  }

  &:not(:last-child){
    margin-bottom: 10px;
  }
`;

export const Button = styled.button`
  font-family: Raleway;
  font-size: 17px;
  font-weight: 700;
  line-height: 18.7px;
  color: #000000;
  max-width: 200px;
  margin: 50px auto 0px;
  border-radius: 15px;
  background: linear-gradient(90deg, #D2D1FF 0%, #FFE1A2 100%);
  padding: 10px 35px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

export const Message = styled.p`
  font-family: Raleway;
  color: ${props => props.isSuccess ? 'green' : 'red'};
  text-align: center;
  margin-top: 15px;
`;
export const LoaderWrapper = styled.div`
  svg path {
    fill: none !important;
  }

`