import * as styled from "./ScheduleGrid.styled";

function ScheduleGrid({
  daysOfWeek,
  lessons,
  handleLessonClick,
  resheduleLesson,
  setSelectedCell, 
  selectedCell
}) {
  

  const handleCellClick = (day, hour) => {
    setSelectedCell({ day, hour });
    resheduleLesson(day, hour);
  };
  return (
    <styled.ScheduleGrid>
      <styled.GridHour>
        <styled.HourLabel></styled.HourLabel>
        {daysOfWeek.map((day, index) => (
          <styled.HourLabel key={index}>{day}</styled.HourLabel>
        ))}
      </styled.GridHour>

      {Array.from({ length: 24 }).map((_, hour) => (
        <styled.GridHour key={hour}>
          <styled.HourLabel>{hour}:00</styled.HourLabel>
          {Array.from({ length: 7 }).map((_, day) => (
            <styled.GridCell
              key={day}
              onClick={() => {
                handleCellClick(day, hour)
              }}
              style={{
                backgroundColor:
                  selectedCell &&
                  selectedCell.day === day &&
                  selectedCell.hour === hour
                    ? "gray"
                    : "initial",
              }}
            >
              {lessons
                .filter((lesson) => lesson.day === day && lesson.hour === hour)
                .map((lesson) => {
                  return (
                    <styled.LessonCard
                      key={`${lesson.name}-${lesson.time}`}
                      style={{ top: `${(lesson.minute / 60) * 100}%` }}
                      onClick={(e) => {
                        e.stopPropagation(); 
                        if (lesson.type === "cancel") return;
                        handleLessonClick(lesson);
                        setSelectedCell(null); 
                      }}
                      istrial={lesson.type === "Trial"}
                      isreschedule={lesson.type === "Reschedule Lesson"}
                      issubstitute={lesson.type === "Substitute"}
                      iscancel={lesson.type === "cancel"}
                    >
                      <styled.StudentName>{lesson.name}</styled.StudentName>
                      <styled.LessonTime>{lesson.time}</styled.LessonTime>
                    </styled.LessonCard>
                  );
                })}
            </styled.GridCell>
          ))}
        </styled.GridHour>
      ))}
    </styled.ScheduleGrid>
  );
}

export default ScheduleGrid;
