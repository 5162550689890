import * as styled from "./PerfectTeacherDropDown.styled"

function PerfectTeacherDropDown({links}){
  return (
    <styled.Wrapper>
      {links.map(({title, link}) => (
         <styled.Item href={link} target="_blank">
            {title}
         </styled.Item>
      ))}
    </styled.Wrapper>
  )
};

export default PerfectTeacherDropDown;
