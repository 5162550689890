import * as styled from "./AdviceDropDown.styled"

function AdviceDropDown({ texts }) {
   return (
     <styled.Wrapper>
       <styled.List>
         {texts.map(({ boldTitle, text }, index) => (
           <styled.Item key={index}>
             <styled.Text>
               <styled.TextBold>{boldTitle}</styled.TextBold> {text}
             </styled.Text>
           </styled.Item>
         ))}
       </styled.List>
     </styled.Wrapper>
   );
 }

export default AdviceDropDown;
