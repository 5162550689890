import styled from "styled-components";

export const Wrapper = styled.div`
   padding: 15px 0px 0px 10px;
   width: 100%;
`
export const ListWrapper = styled.div`
   display: flex; 
 
`
export const List = styled.ul`
   display: flex;
   flex-wrap: wrap;

   gap: 15px;
   @media (min-width: 1280px) {
      gap: 30px;
   }
`

export const Item = styled.li`
   position: relative; 
   padding: ${props => props.isLevel ? '5px' : '10px'};
   box-shadow: 0px 4px 4px 0px ${props => !props.isLevel && !props.isGroup ? "#D2D1FF80": "#676666"};
   background-color: #F9F9F9;
   flex-basis: calc(100% / 3 - 15px);
   border-radius: 5px;
   cursor: pointer;
   transition: all 0.3s ease;

   &::before {
      content: '';
      position: absolute;
      height: ${props => props.isLevel ? '5px' : '8px'};
      left: 0;
      top: 0;
      width: 100%;
      background-color: ${props =>  !props.isLevel && !props.isGroup ? !props.isAlternate ? '#A09EFF' : '#FFC857' : '#676666' };
      transition: all 0.3s ease;
   };

   
   &:hover::before {
      height: ${props => props.isLevel ? '15px' : '20px'};
   }


   &:hover::after {
      height: 20px; 
   };


   @media (min-width: 1280px) {
      flex-basis: calc(100% / 3 - 30px);

      padding: ${props => props.isLevel ? '15px 25px 10px 25px' : '25px 35px 15px 35px'};
   }
`

export const LessonName = styled.p`
   font-family: Raleway;
   font-size:  ${props => props.isLevel ? '14px' : '17px'} ;
   font-weight: 700;
   text-align: center;
   color: #3D3D3D;

   @media (min-width: 1280px) {
      font-size: ${props => props.isLevel ? '17px' : '22px'} ;
   }
`
export const TrialLink = styled.a`
 width: 100%;
`