import * as styled from './Loader.styled'
import loader from '../../assets/animations/loader.json'

function Loader ({customWidth}) {
   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
      rendererSettings: {
         preserveAspectRatio: 'xMidYMid slice'
      }
   };
   return (
      <styled.Wrapper >
         <styled.Loader options={defaultOptions} width={customWidth ? customWidth : 400} height="100%" style={{fill: 'none'}}/>
      </styled.Wrapper>
   )
}

export default Loader