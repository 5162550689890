import { useState, useEffect } from 'react';
import { fetchAuthMe } from '../redux/slices/auth';

const useAuthData = (dispatch) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
         const response = await dispatch(fetchAuthMe());
         setData(response.payload);
    };

    fetchData();
  }, [dispatch]);

  return data;
};

export default useAuthData;
