import * as styled from "./AddTrialLesson.styled"
import {PagesWrapper} from '../../../styles/components/PagesWrapper.styled'
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../axios";
import { CommonBtn } from "../../../styles/components/Button.styled.jsx";
import CurrentStep from "../../../components/CurrentStep/CurrentStep.jsx";
import FirstStep from "../../../components/AddStudentForm/FirstStep/FirstStep.jsx";
import SecondStep from "../../../components/AddStudentForm/SecondStep/SecondStep.jsx";
import ThirdStep from "../../../components/AddStudentForm/ThirdStep/ThirdStep.jsx";
import AddedSuccessfully from "../../../components/AddedSuccessfully/AddedSuccessfully.jsx";
import { firstStepFields } from "../../../helpers/addTrialLesson/firstStepFields";
import { secondStepFields } from "../../../helpers/addTrialLesson/secondStepFields";
import { thirdStepFields } from "../../../helpers/addTrialLesson/thirdStepFields";

function AddTrialLesson(){
   const [successAdded, setSuccessAdded] = useState(null);
   const [currentStep, setCurrentStep] = useState(1)
   const [teacherId, setTeacherId] = useState('')
  const {
   register,
   handleSubmit,
   formState: { errors },
   reset,
   setValue
 } = useForm({
   defaultValues: {
     name: "",
     studentId: "",
     ageGroup: "",
     classSchedule: [],
     phoneNumber: "",
     teacherName: "",
     englishLevel: "",
     email: "",
     password: "",
     userType: "trial-student",
   },
   mode: "onChange",
 });
   

   const onSubmit = async (data) => {
      setCurrentStep(prev => prev + 1);
      const { name, ageGroup, userType, classSchedule, englishLevel, studentId, teacherName, email, password, phoneNumber } = data;
      try {
         const response = await axios.post("trial-lesson", { name, studentId, teacherId, teacherName, userType, ageGroup, classSchedule, englishLevel, email, password, phoneNumber   });

         if(response){
          setSuccessAdded(true);
         }
         reset();
      } catch (error) {
         setSuccessAdded(false);
         console.error("Error adding student:", error);
      }
   };
  
  return (
    <PagesWrapper>
       <TopPageTitle value={"Додати пробний урок"}/>

       {currentStep !== 4 && <CurrentStep currentStep={currentStep}/>}

        <styled.Form onSubmit={handleSubmit(onSubmit)}>

          {currentStep === 1 && <FirstStep isTrial setTeacherId={setTeacherId} firstStepFields={firstStepFields} register={register} setFormValue={setValue} errors={errors}/>}
          {currentStep === 2 && <SecondStep isTrial secondStepFields={secondStepFields} register={register} setFormValue={setValue} errors={errors}/>}
          {currentStep === 3 && <ThirdStep thirdStepFields={thirdStepFields} register={register} setFormValue={setValue} errors={errors}/>}
          {currentStep === 4 && <AddedSuccessfully isSuccess={successAdded} successTitle={'Пробний урок успішно додано'} errorTitle={'Перевірте чи заповнили ви всі розділи'}/>}
          <styled.ButtonWrapper isFirstStep={currentStep === 1}> 
            {(currentStep !== 1 && currentStep !== 4) && <CommonBtn className="back-btn" onClick={() => setCurrentStep(prev => prev - 1)}>Назад</CommonBtn>}
            {currentStep < 3 && <CommonBtn className="next-btn" onClick={() => setCurrentStep(prev => prev + 1)}>Далі</CommonBtn>}
            {currentStep === 3 && <CommonBtn type="submit">Додати</CommonBtn>}
          </styled.ButtonWrapper> 
        
         </styled.Form>
    </PagesWrapper>
  )
};

export default AddTrialLesson;
