import styled from "styled-components";
import {ReactSVG} from 'react-svg'

export const TopWrapper = styled.div`
   display: flex;
   margin-bottom: 45px;

   @media (max-width: 769px){
      align-items: center;
      justify-content: space-between;
   }

   @media (min-width: 768px){
      align-items: baseline;
   }
`
export const TitleWrapper = styled.div`
 display: flex;
 flex-direction: column;
 gap: 5px;
 margin-left: 10px;
`
export const Title = styled.p`
   font-family: Raleway;
   font-size: 22px;
   font-weight: 700;
   line-height: 24.2px;
   
`
export const EnglishLevel = styled.p`
   font-family: Raleway;
   font-size: 12px;
   font-weight: 400;
   line-height: 24.2px;

`

export const Arrow = styled(ReactSVG)`
   cursor: pointer;
   transition: 0.3;

   &:hover{
      opacity: 0.7;
   }
`