import styled from 'styled-components';

export const HamburgerIcon = styled.div`
  width: 30px;
  height: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
`;