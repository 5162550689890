import styled from 'styled-components';
import { CommonBtn } from '../../styles/components/Button.styled';


export const BlockWrapper = styled.div`
 border: 1px solid #000;
 padding: 10px;

 &:not(:last-child){
   margin-bottom: 70px;
 }
`
export const Title = styled.p`
 margin-bottom: 10px;
`;
export const Wrapper = styled.div`
 display: flex;
 gap: 15px;
   & > div{
      width: 100%;
   }
`
export const BtnWrapper = styled.div`
 display: flex;
 justify-content: center;
 width: 100%;
`
export const SubmitBtn = styled(CommonBtn)`
 max-width: 300px;
 font-size: 9px !important;
`