import styled from "styled-components";


export const Wrapper = styled.div`
   padding: 120px 0px 150px;
   height: 100%;
`

export const GroupList = styled.ul `

`

export const GroupItem = styled.li `
   max-width: 310px;
   width: 100%;
   padding: 40px 0px;
   background-color: #F1F1F1;
   box-shadow: 0px 4px 4px 0px #00000040;
   border-radius: 20px;
   cursor: pointer;
   transition: 0.3s;
   &:not(:last-child){
      margin-bottom: 80px;
   }
   &:hover{
      background-color: #FFE1A2;
   }
   @media (min-width: 768px){
      width: 300px;
   }
`

export const GroupTitle = styled.p `
   font-family: Raleway;
   font-size: 18px;
   font-weight: 700;
   line-height: 24.2px;

   text-align: center;
`
