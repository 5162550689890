import styled from "styled-components";
import { Button } from "@mui/material";

export const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`
export const FormWrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 50px 25px 50px;
   border: 1px solid #A09EFF;
   box-shadow: 0px 4px 4px 0px #00000040;

   @media (min-width: 768px) {
      padding: 50px 25px 100px;
   }

`
export const Form = styled.form`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;

`
export const Title = styled.h1`
   font-family: Raleway;
   font-size: 20px;
   font-weight: 900;
   line-height: 29.7px;
   margin-bottom: 65px;

   @media (min-width: 768px) {
      font-size: 27px;
   }
`
export const InputWrapper = styled.div`
   width: 80%;
   &:not(:last-child){
      margin-bottom: 30px !important;
   }, 
`
export const InputName = styled.p`
   font-family: Raleway;
   font-size: 13px;
   font-weight: 800;
   line-height: 14.3px;
   text-align: left;
   margin-bottom: 5px;

`
export const Input = styled.input`
   border: 1px solid #8E8E8E;
   border-radius: 10px ;
   padding: 10px 30px 10px 10px;
   box-shadow: 0px 4px 4px 0px #00000040;
   width: 100%;
   font-size: 14px;
   font-weight: 400;
   &:hover {
      border: 1px solid #A09EFF;
   }

   &::placeholder {
      font-family: Raleway;
      font-size: 16px;
      font-weight: 400;
      line-height: 11px;
     

   }

   /* Стили для автозаполнения */
  &:-webkit-autofill {
   
    -webkit-text-fill-color: black !important; 
  }

 
`

export const ErrorMessage = styled.p`
   color: red;
   font-size:${props => props.notAllowed ? '15px' : '10px'};
   margin: 5px 0px 0px 5px;
  
   ${props => props.notAllowed && `
     margin: 0px 0px 20px 0px;
   `}
`

export const Btn = styled(Button)`
   border: 1px solid #A09EFF !important;
   background: #A09EFF !important;
   width: 70% !important;
   font-family: Raleway !important;
   font-size: 11px !important;
   font-weight: 900 !important;
   line-height: 12.1px !important;
   color: #000000 !important;
   border-radius: 10px !important;
   padding: 13px 0px !important;

`