import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-height: 300px; 
  overflow-y: auto; 
  margin-bottom: 40px;
  border-left: 4px solid #E1E0FF;
  border-right: 4px solid #E1E0FF;
  border-bottom: 4px solid #E1E0FF;
  padding: 20px 20px 20px 20px;
  border-radius: 0px 0px 15px 15px;
`;



export const Item = styled.a`
 padding: 10px;
 border-radius: 10px;
 border: 1px solid #E1E0FF;
`