import { useNavigate } from 'react-router-dom'
import {Button} from './LogoutBtn.styled'
function LogoutBtn() {
   const navigate = useNavigate()
   const logoutClick = () => {
      window.localStorage.removeItem("token")
      navigate('/')
   }
   return (
      <Button onClick={() => logoutClick()}>Вийти</Button>
   )
}

export default LogoutBtn