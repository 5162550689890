export const studentFields = [
   { fieldName: "name" },
   { fieldName: "studentId" },
   { fieldName: "ageGroup" },
   { fieldName: "classSchedule" },
   { fieldName: "lessonPackage" },
   { fieldName: "phoneNumber" },
   { fieldName: "teacherName" },
   { fieldName: "englishLevel" },
   { fieldName: "email" },
   { fieldName: "password" },
 ];

 export const firstStepFields = [
  {name: 'name', inputName: "Ім’я"},
  {name: "studentId", inputName: "Id учня"},
  {name: 'phoneNumber', inputName: "Номер телефону"},
  {name: 'lessonPrice', inputName: "Ціна за один урок"},
]

export const secondStepFields = [
  {
    title: "Вікова група", 
    formName: "ageGroup",
    selectOptions: [
      {name: "Діти 6-12", value: "kids"},
      {name: "Підлітки 13-17", value: "teenagers"},
      {name: "Дорослі", value: "adults"}
    ]
  },
  {
    title: "Рівень володіння", 
    formName: "englishLevel",
    selectOptions: [
      {name: "Starter", value: "starter"},
      {name: "Starter-new*", value: "starter-new"},
      {name: "Beginner", value: "beginner"},
      {name: "Beginner-new*", value: "beginner-new"},
      {name: "Elementary", value: "elementary"},
      {name: "Pre-Intermediate", value: "pre-intermediate"},
      {name: "Pre-Intermediate-new*", value: "pre-intermediate-new"},
      {name: "Intermediate", value: "intermediate"},
    ]
  },
  {
    title: "Пакет уроків", 
    formName: "lessonPackage",
    selectOptions: [
      {name: "8", value: "8"},
      {name: "16", value: "16"},
      {name: "32", value: "32"},
      {name: "50", value: "50"}
    ]
  }
]

export const teachers = [
  {name: "Lera", value: 'lera'},
  {name: "Yana", value: 'yana'},
  {name: "Oksana", value: 'oksana'},
  {name: "Olga", value: 'olga'},
  {name: "Masha", value: 'masha'}
]

export const thirdStepFields = [
  {name: 'email', inputName: "Email"},
  {name: 'password', inputName: "Пароль"},
]