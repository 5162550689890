import * as styled from "./DropDown.styled"
import arrowIcon from '../../../../assets/icons/arrow.svg'

function DropDown({setOpen, title, open}){

  return (
      <styled.TopWrapper onClick={() => setOpen(prev => !prev)} open={open}>
            <styled.TopTitle>{title}</styled.TopTitle>
            <styled.DropDownIcon src={arrowIcon} open={open}/>
      </styled.TopWrapper>
  )
};

export default DropDown;
