import { useEffect, useState } from "react";
import TopPageTitle from "../../../components/TopPageTitle/TopPageTitle";
import { PagesWrapper } from "../../../styles/components/PagesWrapper.styled";
import * as styled from "./MarkedLessons.styled";
import { useSelector } from "react-redux";
import axios from "../../../axios";
import Loader from "../../../components/Loader/Loader";
import HomeworkCard from "../../../components/HomeworkCard/HomeworkCard";
import StudentsDropDown from "../../../components/StudentsDropDown/StudentsDropDown";
function MarkedLessons() {
  const [selectedStudent, setSelectedStudent] = useState("");
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState(null);
  const [lessonId, setLessonId] = useState(null);
  const [homeworkOpen, setHomeworkOpen] = useState(false);
  const { data } = useSelector((state) => state.auth);
  const [students, setStudents] = useState(data.students);
  const [reschedules, setReschedules] = useState([]);

  useEffect(() => {
    if(!data) return
    axios.get(`lesson-reschedule/${data._id}`)
    .then(res => setReschedules(res.data.lesson))
    .catch(err => console.error(err))
  },[data])
  const getLessons = (studentid) => {
    setLoading(true);
    axios.get(`marked-lessons/${studentid}`).then((res) => {
      setLoading(false);
      if(!res.data) {
         return setLessons([])
      }

      setLessons(res.data)

    });
  };
  useEffect(() => {
    if (reschedules.length > 0) {
        const allStudents = [...data.students];

        reschedules.forEach(reschedule => {
            if (reschedule.type === 'teacher-changed') {
                allStudents.push({
                    _id: reschedule.studentId,
                    name: reschedule.studentName,
                    studentId: reschedule.student_id,
                    ageGroup: reschedule.ageGroup,
                    englishLevel: reschedule.englishLevel,
                });
            }
        });

        const uniqueStudents = allStudents.reduce((acc, current) => {
            const x = acc.find(item => item._id === current._id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        console.log(uniqueStudents);
        setStudents(uniqueStudents);
    } else {
        setStudents(data.students);
    }
}, [reschedules, data.students]);

  
  return (
    <PagesWrapper>
      <TopPageTitle value={"Пройдені уроки"} />

        <StudentsDropDown students={students} setSelectedStudent={setSelectedStudent} selectedStudent={selectedStudent} getLessons={getLessons}/>
       
     
      {!loading ? (<styled.List>
         {lessons && lessons.length > 0 && (
            lessons.map(({lessonDate, comment, _id, lesson, lessonName, lessonType, commentForTeacher, homeworkText}, index) => (
              <>
               <HomeworkCard lessonId={_id} setHomeworkOpen={setHomeworkOpen} selectedLesson={lessonId} setLessonId={setLessonId} lessonDate={lessonDate} comment={comment} url={lesson} lessonName={lessonName} lessonType={lessonType} commentForTeacher={commentForTeacher} key={index}/>
               {( _id === lessonId) && (
                <styled.HomeworkSection>
                  <styled.HomeworkText>{homeworkText?.length > 0 ? homeworkText : 'Учень ще не виконав ДЗ'}</styled.HomeworkText>
                  <styled.Actions>
                    {homeworkText?.length > 0 && <styled.Button onClick={() => setLessonId(null)}>Перевірено</styled.Button>}
                    <styled.RejectButton onClick={() => setLessonId(null)}>Закрити</styled.RejectButton>
                  </styled.Actions>
                 </styled.HomeworkSection>
               )}
              </>
              
            ))
            
         )}

        {lessons && lessons.length <= 0 && <p> У вас поки що немає пройдених уроків... 🤔</p>}
      </styled.List> ) : (
        <Loader />
      )}
    </PagesWrapper>
  );
}

export default MarkedLessons;
