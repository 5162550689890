import styled from "styled-components";

export const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 40px;
   width: 100%;
`

export const CheckBoxWrapper = styled.div`
   width: 25%;
`

export const Title = styled.div`
   font-family: Raleway;
   font-size: 13px;
   font-weight: 800;
   line-height: 14.3px;
   margin-bottom: 5px;

`

export const List = styled.ul`
   border: 1px solid #8E8E8E;
   border-radius: 10px;
   padding-top: 10px;
   box-shadow: 0px 4px 4px 0px #00000040;
   max-height: 150px; 
  overflow-y: auto; 
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
   
  margin-bottom: 8px;
  padding: 5px 25px 5px 5px;
  & > input {
    margin-right: 8px;
  }


  &:not(:last-child){
   border-bottom: 1px solid #B4B4B4;
   
  }
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  width: 14px;
  height: 14px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: ${props => props.isChecked ? '#A09EFF' : "white"};
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;

//   &:checked {
//     background-color: #A09EFF;
//     border-color: #A09EFF;
//   }

  &:hover {
    border-color: #A09EFF;
  }
`;

export const Label = styled.label`
   font-family: Raleway;
   font-size: 12px;
   font-weight: 600;
   line-height: 11px;
   color: #5A5A5A;
   margin-right: 35px;
`

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const InputText = styled.p`
   font-family: Raleway;
   font-size: 12px;
   font-weight: 600;
   line-height: 11px;
   color: #5A5A5A;
   margin-right: 5px;
`

export const Input = styled.input`
   font-family: Raleway;
   font-size: 10px;
   font-weight: 400;
   line-height: 11px;
   border: 1px solid #ccc;
   max-width: 140px;
   padding-left: 3px;
`

export const Plus = styled.span`
   margin-left: auto;
   font-size: 16px;
   cursor: pointer;
`