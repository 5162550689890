import { useState, useEffect } from 'react'
import TopPageTitle from '../../../components/TopPageTitle/TopPageTitle'
import * as styled from './Materials.styled'
import { getLevels } from '../../../helpers/levels/getLevels'
import Loader from '../../../components/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { PagesWrapper } from '../../../styles/components/PagesWrapper.styled'

const materialGroups = [
   {name: 'Kids', value: "kids"},
   {name: 'Teenagers', value: "teenagers"},
   {name: 'Adults', value: "adults"},
]
const trialLessons = [
   {name: 'kids', url: 'https://www.canva.com/design/DAGOSuI6pZs/bvLCybHhiM2w_12Isu_wuA/view?utm_content=DAGOSuI6pZs&utm_campaign=designshare&utm_medium=link&utm_source=editor'},
   {name: 'teenagers', url: 'https://www.canva.com/design/DAGB7gy39yo/SSWqGAI-YcTjs1iijsWlFw/view?utm_content=DAGB7gy39yo&utm_campaign=designshare&utm_medium=link&utm_source=editor'},
   {name: 'adults', url: 'https://www.canva.com/design/DAGCBJSLUFY/xJoQxxOy8WkFoo9NM-mNIw/view?utm_content=DAGCBJSLUFY&utm_campaign=designshare&utm_medium=link&utm_source=editor'}
]
function MaterialsTeacher() {
   const [group, setGroup] = useState(null)
   const [levels, setLevels] = useState(null);
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate()
   useEffect(() => {
      if(!group) return
      setLoading(true)
      getLevels(group).then(({levels}) => setLevels(levels)).finally(() => setLoading(false))
   },[group])
   return(
      <PagesWrapper>
         <TopPageTitle value={"Матеріали"}/>
         <styled.List>
            {materialGroups.map(({value, name}, index) => ( 
               <styled.Item isAlternate={index % 2 !== 0} key={value} onClick={() => setGroup(value)} isGroup={group === value}>
                     <styled.LessonName>{name}</styled.LessonName>
               </styled.Item>
            ))}
            {loading 
               ? 
                <Loader/>
               : 
               <>
                  {levels?.map((level) => (
                     <styled.Item isLevel key={level} onClick={() => navigate(`${group}/${level}`)}>
                        <styled.LessonName isLevel>{level}</styled.LessonName>
                     </styled.Item>
                  ))}
                  {trialLessons.map(({name, url}) => {
                     if(name !== group) return null

                     return(
                        <styled.Item isLevel key={name}>
                           <styled.TrialLink href={url} target="_blank" rel="noopener noreferrer">
                              <styled.LessonName isLevel>Trial Lesson</styled.LessonName>
                           </styled.TrialLink>   
                        </styled.Item>
                     )
                     
                  })}
               </>
            }
         </styled.List>
      </PagesWrapper>
   )
}

export default MaterialsTeacher