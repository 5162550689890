import SelectDayTime from "../SelectDayTime/SelectDayTime";
import StudentsDropDown from "../StudentsDropDown/StudentsDropDown";
import * as styled from "./LessonChangesBlock.styled"

function LessonChangesBlock({title, students, setSelectedStudent, selectedStudent, teachers, setSelectedTeacher, selectedTeacher, selectedDayTime, setSelectedDayTime, message, handleSubmit, btnText}){
  return (
   <styled.BlockWrapper>
      <styled.Title>{title}</styled.Title>
      <styled.Wrapper>
         <StudentsDropDown students={students} setSelectedStudent={setSelectedStudent} selectedStudent={selectedStudent}/>
         <StudentsDropDown placeholder={'вчителя'} students={teachers} setSelectedStudent={setSelectedTeacher} selectedStudent={selectedTeacher}/>
         <SelectDayTime selectedDayTime={selectedDayTime} setSelectedDayTime={setSelectedDayTime}/>
      </styled.Wrapper>
      <styled.BtnWrapper>
         {message.length > 0 && <p>{message}</p>}
         <styled.SubmitBtn onClick={handleSubmit}>{btnText}</styled.SubmitBtn>
      </styled.BtnWrapper>
   </styled.BlockWrapper>
  )
};

export default LessonChangesBlock;
