import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 10px 30px 0px 0px;

  @media (min-width: 1280px) {
    padding: 20px 35px 20px 10px;
  }
`;

export const StudentsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StudentItem = styled.li`
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const StudentAvatar = styled.div`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

export const AvatarIcon = styled.div`
 
  font-size: 24px;
`;

export const StudentDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StudentName = styled.h3`
  font-family: Raleway;
  margin: 0;
  font-size: 18px;
  color: #333;
`;

export const StudentInfo = styled.p`
  font-family: Raleway;
  margin: 2px 0;
  font-size: 14px;
  color: #666;
`;

export const EditButton = styled.button`
  font-family: Raleway;
  background-color: #A09EFF;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    opacity: 0.7
  }
`;

export const SaveButton = styled.button`
  font-family: Raleway;
  background-color: #28a745;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }
`;

export const Select = styled.select`
  font-family: Raleway;
  margin-left: 5px;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;
