import styled from 'styled-components';


export const List = styled.ul`
 display: flex;
 flex-direction: column;
 gap: 20px;
`

export const Item = styled.li`

`

export const HomeworkSection = styled.div`
  margin-bottom: 100px;
  padding: 20px;
  border-top: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  border-radius: 0 0 8px 8px;
`;

export const HomeworkText = styled.pre`
  font-family: Raleway;
  font-size: 16px;
  color: #333333;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  white-space: pre-wrap; 
  word-wrap: break-word; 
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Actions = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button`
  font-family: Raleway;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #45a049;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #3e8e41;
  }
`;

export const RejectButton = styled(Button)`
  background-color: #e74c3c;

  &:hover {
    background-color: #c0392b;
  }

  &:active {
    background-color: #a93226;
  }
`;