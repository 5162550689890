import * as styled from "./TextInput.styled.jsx";

function TextInput({ fieldName, name, register, placeholder, onClick, errors }) {
  
  return (
    <styled.Wrapper>
      <styled.InputName>{fieldName}</styled.InputName>
      <styled.Input
        name={name}
        placeholder={placeholder ? placeholder : "type..."}
        {...register(name, { required: `Please, write the ${fieldName}` })}
        onClick={() => {
         if(onClick){
            onClick()
         }
        }}
      />
      <styled.ErrorMessage>{errors ? errors[name]?.message : ''}</styled.ErrorMessage>
    </styled.Wrapper>
  );
}

export default TextInput;
