import { useState } from "react";
import TextInput from "../../TextInput/TextInput.jsx";
import * as styled from "./FirstStep.styled.jsx";
import SelectTeacher from "../../../components/SelectTeacher/SelectTeacher"; 

function FirstStep({ firstStepFields, register, setFormValue, errors, setTeacherId, teacherTrialLesson, setTeacherTrialLesson, isTrial }) {
  const [teacherClick, setTeacherClick] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [teacherTrialClick, setTeacherTrialClick] = useState(false);
  const [selectedTrialTeacher, setSelectedTrialTeacher] = useState("");


  const handleClick = (value, name) => {
    setTeacherId(value)
    setFormValue("teacherName", name);
    setSelectedTeacher(name);
    setTeacherClick(false);
  };

  const handleTeacherTrialClick = (id, name) => {
    setTeacherTrialLesson(id)
    setSelectedTrialTeacher(name)
    setTeacherTrialClick(false)
  }

  return (
    <styled.Wrapper>
      {firstStepFields.map(({ name, inputName }) => (
        <TextInput
          key={name}
          fieldName={inputName}
          name={name}
          register={register}
          errors={errors}
        />
      ))}
      <SelectTeacher setLoading={setLoading} loading={loading} setTeachers={setTeachers} teachers={teachers} setTeacherClick={setTeacherClick} teacherClick={teacherClick} handleClick={handleClick}  selectedTeacher={selectedTeacher}/>
      {!isTrial && <SelectTeacher setLoading={setLoading} loading={loading} setTeachers={setTeachers} teachers={teachers} setTeacherClick={setTeacherTrialClick} teacherClick={teacherTrialClick} handleClick={handleTeacherTrialClick}  selectedTeacher={selectedTrialTeacher} title={"Вчитель - пробний урок"}/>}
    </styled.Wrapper>
  );
}

export default FirstStep;
