import axios from '../../axios'

export const fetchLastLesson = async (studentId) => {
   try {
     const response = await axios.get(`/students/studentId/${studentId}/last-lesson`);
     return response.data;
   } catch (error) {
     console.error('Error fetching last lesson:', error);
     throw error;
   }
};
 
export const fetchAllLessons = async (studentId) => {
   try {
     const response = await axios.get(`/students/studentId/${studentId}/lessons`);
     return response.data;
   } catch (error) {
     console.error('Error fetching all lessons:', error);
     throw error;
   }
};