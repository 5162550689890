import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import { Provider } from 'react-redux';
import {persistor, store} from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import ScrollToTop from './components/ScrollTop/ScrollTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
     <ScrollToTop />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          </PersistGate>
      </Provider>
       
    </BrowserRouter>
    
  </React.StrictMode>
);

