export const adviceStudent = [
   {
     boldTitle: "Занурення:",
     text: "Пориньте в англомовне середовище. Слухайте англійську музику, дивіться фільми та серіали, читайте книги та новини англійською мовою. На ваших уроках ви знайдете все необхідне для цього.",
   },
   {
     boldTitle: "Практика спілкування:",
     text: "Використовуйте можливості спілкування з викладачами, щоб покращити вимову та розуміння розмовної англійської.",
   },
   {
     boldTitle: "Щоденна практика:",
     text: "Виділяйте хоча б 15-30 хвилин щодня на вивчення англійської. Регулярність допомагає краще засвоювати новий матеріал.",
   },
   {
     boldTitle: "Граматика та лексика:",
     text: "Працюйте над граматикою та лексикою паралельно. На ваших уроках з EnglishLoom ви знайдете структуровані курси з граматики та лексичний матеріал.",
   },
   {
     boldTitle: "Встановлення цілей:",
     text: "Встановлюйте конкретні, досяжні цілі для вивчення англійської. На платформі  ви можете відстежувати свій прогрес і бачити свої досягнення.",
   },
   {
     boldTitle: "Культурний контекст:",
     text: "Дізнавайтеся більше про англомовні країни та їх культуру на ваших уроках. Це допоможе вам краще розуміти контексти використання певних фраз та виразів.",
   },
];

export const rules = [
   {
      boldTitle: "Скасування/Перенесення Уроку:",
      text: "Скасувати/Перенести урок ви можете на сторінці 'Графік'",
    },
    {
      boldTitle: "Заміна Вчителя:",
      text: "У випадку відміни уроку викладачем, ми забезпечимо заміну викладача, або перенесемо урок на інший зручний для всіх день.",
    },
    {
      boldTitle: "Скасування Уроку та Відшкодування:",
      text: "Прохання скасовувати уроки щонайменше за годину до початку. В іншому випадку урок вважатиметься пройденим, за винятком непередбачуваних ситуацій. У разі бажання учня завершити навчання в нашій школі, проте залишаються оплачені уроки, варто зазначити, що гроші за залишкові уроки не підлягають поверненню. Однак у вас є можливість призупинити навчання і повернутися до нього у зручний для себе час.",
    },
    {
      boldTitle: "Запізнення та Відсутність Вчителя:",
      text: "Запізнення учня на урок не компенсується часом. У випадку відсутності вчителя без попередження, пропущений урок буде перенесений на інший зручний для вас час, і вам буде надано додатковий урок безкоштовно.",
    },
    {
      boldTitle: "Акція 'Приведи Друга':",
      text: "У нашій школі діє акція 'Приведи Друга'. Якщо ваш друг здійснить вступ на наші заняття за вашою рекомендацією, обидва отримаєте 2 безкоштовні уроки.",
    },
    {
      boldTitle: "Тривалість уроків:",
      text: "Діти - 45хв., підлітки/дорослі - 50хв.",
    },
]
export const newFeatureTitleStudent = "Червоний, якщо скасування 🔴"
export const newFeatureStudent = [
  {number: '', text: 'Картка червоного кольору з‘явиться у вашому розкладі після скасування уроку.'},
  {number: '', text: 'Через тиждень картка знову стане жовтою, адже скасування є разовим.'},
  
]