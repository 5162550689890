import styled from "styled-components";

export const Wrapper = styled.div`
   &:not(:last-child){
      margin-bottom: 15px;
   }
`
export const InputName = styled.p`
   font-family: Raleway;
   font-size: 13px;
   font-weight: 800;
   line-height: 14.3px;
   text-align: left;
   margin-bottom: 5px;

`
export const Input = styled.input`
   border: 1px solid #8E8E8E;
   border-radius: 10px ;
   padding: 10px 30px 10px 10px;
   box-shadow: 0px 4px 4px 0px #00000040;

   font-size: 14px;
   font-weight: 400;
   &:hover {
      border: 1px solid #A09EFF;
   };

   &::placeholder {
      font-family: Raleway;
      font-size: 16px;
      font-weight: 400;
      line-height: 11px;
     

   };
   &:-webkit-autofill {
   
    -webkit-text-fill-color: black !important; 
   };
`

export const ErrorMessage = styled.p`
   color: red;
   font-size: 10px;
   margin: 5px 0px 0px 5px;
`