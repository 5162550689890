import { ReactSVG } from "react-svg";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
   font-family: Raleway;
  position: relative;
  background: #B4B4B412;
  box-shadow: 0px 4px 10px 0px #9C9C9C40;
  padding: 35px 10px 45px;
  margin-top: 60px;
  border-radius: 15px;
`

export const Title = styled.p`
   font-family: Raleway;
   font-size: 14px;
   font-weight: 700;
   line-height: 16.5px;
   text-align: center;
   margin-bottom: 25px;

`

export const List = styled.ul`


`

export const Item = styled.li`
   display: flex;
   align-items: center;
   &:not(:last-child){
      margin-bottom: 15px;
   }
`

export const NumberWrapper = styled.div`
   padding: 5px 8px;
   background-color: #A09EFF;
   border-radius: 25px;
   margin-right: 5px;
`

export const Number = styled.p`
   font-family: Raleway;
   font-size: 10px;
   font-weight: 500;
   line-height: 11px;
   color: #fff;
`

export const Text = styled.p`
   font-family: Raleway;
   font-size: 12px;
   font-weight: 500;
   line-height: 13.2px;
`

export const Icon = styled(ReactSVG)`
   position: absolute;
   left: -3%;
   top: -18%;
   @media (min-width: 768px){
     left: 0%;
     top: -13%;
   };
  @media (min-width: 1280px){
    left: -13%;
    top: -17%;
  }
`