export const studentLevels = [
   {name: 'Новачок' , value: 0},
   {name: 'Початківець' , value: 10},
   {name: 'Учень' , value: 20},
   {name: 'Просунутий учень' , value: 30},
   {name: 'Знавець основ' , value: 40},
   {name: 'Аматор' , value: 50},
   {name: 'Спеціаліст' , value: 60},
   {name: 'Профі' , value: 70},
   {name: 'Майстер своєї справи' , value: 80},
   {name: 'Експерт' , value: 90},
   {name: 'Віртуоз' , value: 100},
   {name: 'Гуру' , value: 110},
   {name: 'Майстер' , value: 120},
   {name: 'Учитель' , value: 130},
   {name: 'Наставник' , value: 140},
   {name: 'Тренер' , value: 150},
   {name: 'Професор' , value: 160},
   {name: 'Доктор слів' , value: 170},
   {name: 'Магістр англійської' , value: 180},
   {name: 'Найбільший знавець' , value: 190},
]

