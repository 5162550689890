import styled from "styled-components";
import { Button } from "@mui/material";

export const CommonBtn = styled(Button)`
   border: 1px solid #A09EFF !important;
   background: #A09EFF !important;
   width: 70% !important;
   font-family: Raleway !important;
   font-size: 11px !important;
   font-weight: 900 !important;
   line-height: 12.1px !important;
   color: #000000 !important;
   border-radius: 10px !important;
   padding: 13px 0px !important;
   transition: 0.3s !important;
   &:hover{
      opacity: 0.7 !important;
   }

   ${props => props.currentStep && `
      display: none;
   `}
`